import moment from 'moment';
import { AlertType } from '../../../models/shared.model';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import numeral from 'numeral';
import 'numeral/locales';


export class CommonMethods {

/**
 * jsonObj.members.push(jsonTmp); // ass one node at end of list
 */
  public static jsonAddNode( jsonTargetArg: any, atLastArg: boolean, jsonStringArg: string, indexArg: number): any {
    const jsonArg = JSON.parse(jsonStringArg); // convert string to json
    if (atLastArg) {
      jsonTargetArg.push(jsonArg);
    } else {
      jsonTargetArg.splice(indexArg, 0, jsonArg);
    }
    return jsonTargetArg;
  }
  /**
   * jsonObj.members.splice(0, 1); // delete member index 0 alone properly, for delete severals rows replace 1 by the number to delete.
   * delete jsonObj.members[0]; // if node : null at deleted position :
   */
  public static jsonDelete(jsonTargetArg: any, jsonKeyArg: string, startArg: number, endArg: number): any {
    if (!jsonKeyArg) { // delete node
      jsonTargetArg.splice(startArg, endArg);
    } else { // delete key
      for (const jsonCur of jsonTargetArg) {
        delete jsonCur[jsonKeyArg];
      }
      return jsonTargetArg;
    }
  }
  /**
   * Update key on path Target
   */
  public static jsonUpdateKey(jsonTargetArg: any, jsonStringArg: string): any {
    const jsonArg = JSON.parse(jsonStringArg);
    jsonTargetArg = jsonArg;
  }
  /**
   * adding propertie (key/value) on target
   */
  public static jsonAddKey(jsonTargetArg: any, jsonKeyArg: string, jsonValueArg: string, indexArg: number): any {

    if (indexArg < 0) {
      jsonTargetArg[jsonKeyArg] = jsonValueArg ;
    } else {
      jsonTargetArg[indexArg][jsonKeyArg] = jsonValueArg ;
    }
    return jsonTargetArg;
  }
  public static getAlertType(messageType: string): any {

    switch (messageType) {
        case 'SUCCESS':
            return AlertType.Success;
        case 'ERROR':
            return AlertType.Error;
        case 'INFO':
            return AlertType.Info;
        case 'IMPORTANT':
            return AlertType.Important;
        case 'WARNING':
            return AlertType.Warning;
    }
}
/**
 * test email format
 */
  public static controlEmail(emailarg: string): boolean {
    const reg = new RegExp('^[a-z0-9]+([_|\.|-]{1}[a-z0-9]+)*@[a-z0-9]+([_|\.|-]{1}[a-z0-9]+)*[\.]{1}[a-z]{2,6}$', 'i');
    return(reg.test(emailarg));
  }

  public static controlForbiddenWords(textRequestArg: string, forbiddenWordsArg: string): boolean {
    const reg = new RegExp('(?i)(\W|^)(' + forbiddenWordsArg + ' )(\W|$)', 'i');
    return(reg.test(textRequestArg));
  }
  public static dateToTZ(dateCur: any, formatTZ: string): string {
    return moment(dateCur).format(formatTZ);
  }
  public static dateToString(bdDateArg: any , formatOrigArg: string, bddFormatArg: string): string {
    let displayDate = null;
    let parsingUnix = null;
    const myDate = new Date();
    const offSet = myDate.getTimezoneOffset() * -1; // difference between UTC and local time zone

    switch (formatOrigArg) {
      case 'unixM': {
        const regex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(?:\.(\d{3}))?(Z|[\+-]\d{4}(?::\d{2})?)$/ ;

        const bdDate = bdDateArg as string;
        const stringCur = String(bdDate);
        parsingUnix = stringCur.match(regex);
        const monthUTC = Number.parseInt(parsingUnix[2], 0) - 1 ;
        myDate.setUTCFullYear(Number.parseInt(parsingUnix[1], 10));
        myDate.setUTCMonth(monthUTC); // The setUTCMonth() method sets the month (from 0 to 11), according to universal time
        myDate.setUTCDate(Number.parseInt(parsingUnix[3], 10));
        myDate.setUTCHours(Number.parseInt(parsingUnix[4], 10));
        myDate.setUTCMinutes(Number.parseInt(parsingUnix[5], 10));
        myDate.setUTCSeconds(Number.parseInt(parsingUnix[6], 10));
        myDate.setUTCMilliseconds(Number.parseInt(parsingUnix[7], 10));

        displayDate = moment(myDate).add(offSet, 'minutes').format(bddFormatArg);
        break;
      }
      case 'json': {
        const regex = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})(?:\.(\d{3}))$/ ;
        const stringCur = bdDateArg as string;
        parsingUnix = stringCur.match(regex);
        const monthUTC = Number.parseInt(parsingUnix[2], 0) - 1 ;
        myDate.setUTCFullYear(Number.parseInt(parsingUnix[1], 10));
        myDate.setUTCMonth(monthUTC); // The setUTCMonth() method sets the month (from 0 to 11), according to universal time
        myDate.setUTCDate(Number.parseInt(parsingUnix[3], 10));
        myDate.setUTCHours(Number.parseInt(parsingUnix[4], 10));
        myDate.setUTCMinutes(Number.parseInt(parsingUnix[5], 10)); // timezone offset set here, after hours
        myDate.setUTCSeconds(Number.parseInt(parsingUnix[6], 10));
        myDate.setUTCMilliseconds(Number.parseInt(parsingUnix[7], 10));
        // displayDate = moment(myDate).add(offSet, 'minutes').format(bddFormatArg);
        displayDate = moment(myDate).format(bddFormatArg);
        break;
      }
      case 'json1': {
        const regex = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})(?:\.(\d{3}))$/ ;
        const stringCur = bdDateArg as string;
        bddFormatArg = bddFormatArg.replace('dd', 'DD');
        bddFormatArg = bddFormatArg.replace('yyyy', 'YYYY');
        // displayDate = moment(myDate).add(offSet, 'minutes').format(bddFormatArg);
        displayDate = moment(stringCur).format(bddFormatArg);
        break;
      }
      case 'date': {
        displayDate = moment.unix(bdDateArg / 1000).format(bddFormatArg);
        break;
      }
      case 'hour': {
        displayDate = moment.unix(bdDateArg / 1000).format(bddFormatArg);
        break;
      }
      default: {
        displayDate = moment.unix(bdDateArg / 1000).format(bddFormatArg);
        break;
      }
    }
    return displayDate;

  }
  // https://bobbyhadz.com/blog/javascript-format-date-yyyy-mm-dd-hh-mm-ss
  public static  padTo2Digits(num: number): string {
    return num.toString().padStart(2, '0');
  }
  public static formatDate(date: any): string {
    return (
      [
        date.getFullYear(),
        this.padTo2Digits(date.getMonth() + 1),
        this.padTo2Digits(date.getDate()),
      ].join('-') +
      ' ' +
      [
        this.padTo2Digits(date.getHours()),
        this.padTo2Digits(date.getMinutes()),
        this.padTo2Digits(date.getSeconds()),
      ].join(':')
    );
  }
  public static dateMomentForBdd(bdDateArg: any , bddFormatArg: string): string {

    if ((bddFormatArg === undefined) || (bddFormatArg === null)) {
      bddFormatArg = `${environment.fmtDateTimeBdd}`;
    }
    if ((bdDateArg === undefined) || (bdDateArg === null)) {
      bdDateArg = moment();
    }

    const finalDate = moment.unix(bdDateArg / 1000).format(bddFormatArg);
   // console.log(finalDate);
    const finalMoment = moment(bdDateArg, bddFormatArg);

    return finalDate;
  }
  public static listToArray(fullStringArg: string, separatorArg: string): string[]  {
    let fullArray: string[] = [];
    if (fullStringArg !== undefined) {
      if (fullStringArg.indexOf(separatorArg) === -1) {
        fullArray.push(fullStringArg);
      } else {
        fullArray = fullStringArg.split(separatorArg);
      }
    }
    return fullArray;
  }
  public static arrayStringToString(arrayArg: string[], separatorArg: string ): string {
      let fullString = '';
      if (arrayArg !== undefined) {
        for (const unitString of arrayArg) {
          if (fullString === '') {
            fullString = fullString.concat( unitString);
          } else {
            fullString = fullString.concat( separatorArg + unitString);
          }
        }
      }
      return fullString;
  }
  public static arrayValueToString(arrayArg: any[], separatorArg: string ): string {
    let fullString = '';
    if (arrayArg !== undefined) {
      for (const unitString of arrayArg) {
        if (fullString === '') {
          fullString = fullString.concat( unitString.value);
        } else {
          fullString = fullString.concat( separatorArg + unitString.value);
        }
      }
    }
    return fullString;
}
  public static jsonToArray(structureInputArg: any): any[] {
    const localString = JSON.stringify(structureInputArg);
    // const usersJson: any[] = Array.of(this.conditions);
    const parsed = JSON.parse(localString);
    const arr = [];
    for (const x of parsed) {
      arr.push(x);
    }
    return arr;
  }
  public static jsonPropToArray(structureInputArg: any, propertyNameArg: string): any[] {
    const localString = JSON.stringify(structureInputArg);
    // const usersJson: any[] = Array.of(this.conditions);
    const parsed = JSON.parse(localString);
    const arr = [];
    for (const x of parsed) {
      arr.push(x[propertyNameArg]);
    }
    return arr;
  }
  public static filterArray(itemList: any, searchKeyword: string): any {
    if (!itemList) {
      return [];
    }
    if (!searchKeyword) {
      return itemList;
    }
    const filteredList: any[] = [];
    if (itemList.length > 0) {
      searchKeyword = searchKeyword.toLowerCase();
      itemList.forEach((item: { [s: string]: unknown; } | ArrayLike<unknown>) => {
        // Object.values(item) => gives the list of all the property values of the 'item' object
        const propValueList = Object.values(item);
        for (const propValueCur of propValueList)  {
          if (propValueCur.toString().toLowerCase().indexOf(searchKeyword) > -1) {
            filteredList.push(item);
            break;
          }
        }
      });
    }
    return filteredList;
  }
  public static optionsSelected(translateService: TranslateService, dataColumnsDefArg: any[],
                                listNameArg: string[], listSelectedArg: any[], jumpFirstArg: boolean ): any[] {
    const listOptions = [];
    let curId = 0;
    let isSelectedTmp: boolean;
    for (const name of listNameArg) {
      if (jumpFirstArg && curId === 0) {
        curId++;
        continue;
      }
      // console.log('optionsSelected colonne : ' + name);
      isSelectedTmp = false;
      /* if (listSelectedArg.indexOf(name) > -1) {
        isSelected = true;
      } */
      const selectedOption = listSelectedArg.find(c => {
        return c.prop === name ;
      });
      const dataType = dataColumnsDefArg.find(c => {
        if (c.colName === name) {
          return c.colType;
        }
      });
      if (selectedOption) {
        isSelectedTmp = true;
      }
      if (dataType === undefined) {
        console.log('undefined type : ' + name);
      } else if (dataType.colType === undefined) {
        console.log('undefined colType : ' + name);
      }
      listOptions.push({
        label: translateService.instant(name),
        value: name,
        isSelected: isSelectedTmp,
        dataType: dataType.colType
      });
      curId++;
    }
    return listOptions;
  }
  public static removeOption(listOptionsArg: any[], optionToDeletearg: string): any[] {
    const listOptions = [];
    for (const dataname of listOptionsArg) {
      if (dataname.prop === optionToDeletearg) {
        continue;
      }
      listOptions.push(
        {
          prop: dataname.prop,
          name: dataname.name
        });
    }
    return listOptions;
  }
  public static addOption(listOptionsArg: any[],  dataColumnsDefArg: any[], optionToAddArg: string): any[] {
    const listOptions = [];
    let dataType: any;
    let colDate = false;
    const nbOptions = listOptionsArg.length;
    for (const dataname of listOptionsArg) {
      // insertion avant les boutons action
      dataType = dataColumnsDefArg.find(c => {
        if (c.colName === dataname) {
          return c.colType;
        }
      });
      colDate = false;
      if (dataType === 'Date') {
        colDate = true;
      }
      if (dataname === 'actions') {
        listOptions.push(
        {
          prop: optionToAddArg,
          name: optionToAddArg,
          isDate: colDate
        });
        listOptions.push(dataname);
        break;
      } else {
        listOptions.push(dataname);
        if (listOptions.length === nbOptions) {
          dataType = dataColumnsDefArg.find(c => {
            if (c.colName === optionToAddArg) {
              return c.colType;
            }
          });
          colDate = false;
          if (dataType === 'Date') {
            colDate = true;
          }
          listOptions.push(
            { prop: optionToAddArg,
              name: optionToAddArg});
        }
      }
    }
    return listOptions;
  }
  public static removeItemFromArray(listeArrayArg: any[], pos: number): any[] {
    const listeArray = listeArrayArg;
    listeArray.slice(0, pos - 1).concat(listeArray.slice(pos, listeArray.length));
    return listeArray;
  }

  public static randomString(len: number, charSet?: string): string {
    charSet = charSet || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890';
    let randomString = '';
    for (let i = 0; i < len; i++) {
        const randomPoz = Math.floor(Math.random() * charSet.length);
        randomString += charSet.substring(randomPoz, randomPoz + 1 );
    }
    return randomString;
  }

  public static fmtNumber(valueArg: number, fmtArg: string, langArg: string): string {
    let result: string;
    numeral.locale(langArg);
    let fmtString: string;
    // http://numeraljs.com/
    switch (fmtArg) {
      case 'integer': {
        fmtString = '0,0';
        break;
      }
      case 'dec1' : {
        fmtString = '0,0.0' ;
        break;
      }
      case 'dec2' : {
        fmtString = '0,0.00' ;
        break;
      }
      case 'zero_left_Int5' : {
        fmtString = '00000' ;
        break;
      }
      case 'zero_left_Int6' : {
        fmtString = '000000' ;
        break;
      }
      case 'decCurrency2':  {
        fmtString = '0,0[.]00 $';
        break;
      }
      case 'intCurrency' : {
        fmtString = '0,0 $';
        break;
      }
      case 'percent' : {
        fmtString = '0%';
        break;
      }
      default : {
        fmtString = '0.0';
        break;
      }
    }

    result = numeral(valueArg).format(fmtString);
    return result;
  }

  public static sleep(milliseconds: number): void {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }
  /**
   *  http://www.webanalytix.fr/comment-encoder-les-caracteres-speciaux-dans-les-urls/
   *  https://www.w3schools.com/tags/ref_urlencode.asp
   */
  public static cleanURLString(strToClean: string): string {
    strToClean = strToClean.replace(/[&]/g, '%26');
    strToClean = strToClean.replace(/[ ]/g, '%20');
    strToClean = strToClean.replace(/[-]/g, '%2D');


    return strToClean;
  }
  public static cleanString(strToClean: string): string {

    strToClean = strToClean.replace(/[&]/g, '');
    strToClean = strToClean.replace(/[ ]/g, '');
    strToClean = strToClean.replace(/[.]/g, '');
    strToClean = strToClean.replace(/[,]/g, '');
    strToClean = strToClean.replace(/[-]/g, '');
    strToClean = strToClean.replace(/[_]/g, '');
    strToClean = strToClean.replace(/[?]/g, '');
    strToClean = strToClean.replace(/[(]/g, '');
    strToClean = strToClean.replace(/[[]/g, '');
    strToClean = strToClean.replace(/[]]/g, '');
    strToClean = strToClean.replace(/[)]/g, '');
    strToClean = strToClean.replace(/[=]/g, '');
    strToClean = strToClean.replace(/[+]/g, '');
    strToClean = strToClean.replace(/["]/g, '');
    strToClean = strToClean.replace(/[']/g, '');

    return strToClean;
  }
  public static isUUID( uuid: string ): boolean {
    const s = '' + uuid;

    const matcherString = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
    if (matcherString === null) {
      return false;
    }
    return true;
  }
}
