import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { take, takeUntil, throwIfEmpty } from 'rxjs/operators';
import { WksTasksModel } from 'src/app/models';
import { LabelValue, WksEntity } from 'src/app/models/shared.model';
import { WksEquipmentModel } from 'src/app/models/works/wks-equipment.model';

import { PhotoData, WksWorksModel, WorkEquipmentRef } from 'src/app/models/works/wks-works.model';
import { AppointmentService } from 'src/app/services/appointment.service';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { WorksService } from 'src/app/services/works.service';

@Component({
  selector: 'app-work-photos',
  templateUrl: './work-photos.component.html',
  styleUrls: ['./work-photos.component.less']
})
export class WorkPhotosComponent implements OnChanges {

  @Input() wksWorks: WksWorksModel;
  @Input() encodedReference!: string;
  @Input() callOrigin!: string;
  @Input() wksEntity!: WksEntity;

  private readonly onDestroy = new Subject<void>();
  @ViewChild('sidenav', {static: false}) sidenav: MatSidenav;

  taskLabel: string;
  userName: string;
  userLang: string;

  stdEntity: string;

  stdEntityTest = 'TST_1558';
  userNameTest = 'jlg';
  userLangTest = 'fr';

  workIsLoaded: boolean;
  hasBackdrop: boolean;
  sidenavCollapsed: boolean;
  equipmentsList: LabelValue[] = [];
  workEquipmentsId: WorkEquipmentRef;
  tasksList: LabelValue[] = [] ;
  sequenceAttRef: string[] = ['BEFORE', 'DURING', 'AFTER'] ;
  sequenceAttList: LabelValue[];
  workTasksContent: WksTasksModel[];

  photoData: PhotoData;
  photoDataBase64: string;
  photoDataForm: FormGroup;
  displayMenuAccess: boolean;
  selectedEquipLabel: string;

  constructor(private fb: FormBuilder,
              private appointmentService: AppointmentService,
              private authService: AuthService,
              private userService: UserService,
              private worksService: WorksService,
              private translate: TranslateService) { }

  ngOnChanges(changes: SimpleChanges): void {

    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'wksWorks': {
            this.wksWorks = changes.wksWorks.currentValue;
            break;
          }
          case 'encodedReference': {
            this.encodedReference = changes.encodedReference.currentValue;
            break;
          }
          case 'wksEntity': {
            this.wksEntity = changes.wksEntity.currentValue;
            break;
          }
          case 'callOrigin': {
            this.callOrigin = changes.callOrigin.currentValue;
            break;
          }
        } // end switch
      } // end if
    }

    this.initData();
  }
  initData(): void {

    this.initBooleans();
    this.initVariables();

    if (this.wksWorks === undefined) {
      if ( this.callOrigin === 'linkPhotos') {
        this.userLang = this.userService.getNavigatorLanguage();
        this.stdEntity = this.wksEntity.stdEntity;
        this.loadLinkWork();
      } else {
        this.userLang = this.userLangTest;
        this.userName = this.userNameTest;
        this.stdEntity = this.stdEntityTest;
        this.loadLastWork();
      }
    } else {
      this.userName = this.authService.getUserLogged().username;
      this.userLang = this.authService.getUserLogged().userlang;
      this.stdEntity = this.wksWorks.stdEntity;
      this.workEquipmentsId = JSON.parse(this.wksWorks.workEquip);
      this.workTasksContent = this.wksWorks.workTasksContent;
      this.loadEquipments();
      this.workIsLoaded = true;
      this.buildForm();
      this.toggleSidenav('init');
    }
  }
  initBooleans(): void {
    this.workIsLoaded = false;
    this.hasBackdrop = true;
    this.sidenavCollapsed = true;
    this.displayMenuAccess = false;
  }
  initVariables(): void {
    this.selectedEquipLabel = '';
  }
  /**
   *  JLG - 2024-11-18
   *  Translation data should not be called up too early in the process.
   *  Otherwise they won't be translated.
   *  Loading of translation files has not been completed.
   */

  initAttributes(): void {

    const labeli18nPath = 'WORK_PHOTOS.';
    this.sequenceAttList = [];
    let itemTmp: LabelValue;
    for (const attItem of this.sequenceAttRef) {
      itemTmp =  {
        label: this.translate.instant(labeli18nPath + attItem),
        value: attItem
      };
      this.sequenceAttList.push(itemTmp);
    }
    itemTmp =  {
      label: '',
      value: undefined
    };
    this.sequenceAttList.unshift(itemTmp);
  }
  loadLinkWork(): void  {
    this.worksService.getWorksLinkWork(this.stdEntity, this.encodedReference )
    .subscribe(
      data => {
        const photoDataTmp = data.body as PhotoData;

        this.worksService.getWorksByLink(photoDataTmp.workId, this.userLang, this.stdEntity)
        .subscribe(
          dataWork => {
            this.wksWorks = dataWork.body as WksWorksModel;
            this.workEquipmentsId = JSON.parse(this.wksWorks.workEquip);
            this.workTasksContent = this.wksWorks.workTasksContent;
            this.loadEquipments()
            .then ((data1: any) => {
              this.initAttributes();
              this.buildForm();
              this.onChangeEquip(photoDataTmp.equipId);
              this.photoDataForm.controls.equipSelect.setValue(photoDataTmp.equipId);
              this.photoDataForm.controls.taskSelect.setValue(photoDataTmp.taskId);
              this.photoDataForm.controls.sequenceSelect.setValue(photoDataTmp.sequenceAtt);
              this.photoData = photoDataTmp;
              this.photoDataBase64 = btoa(JSON.stringify(this.photoData));
              this.userName = this.wksWorks.ownerId;
              this.workIsLoaded = true;
              this.toggleSidenav('init');
            })
            .catch ((error: any) => {

            });

          },
          err =>  {
            console.log('getWorksByLink ' + err.message);
          }
        );
      },
      err =>  {

        console.log('loadLinkWork ' + err.message);
      }
    );
  }
  loadLastWork(): void  {
    this.worksService.getWorksLastWork(this.stdEntity, this.userLang )
    .subscribe(
      data => {
        this.wksWorks = data.body as WksWorksModel;
        this.workEquipmentsId = JSON.parse(this.wksWorks.workEquip);
        this.workTasksContent = this.wksWorks.workTasksContent;
        this.loadEquipments()
        .then ((data1: any) => {
          this.initAttributes();
          this.buildForm();
          this.workIsLoaded = true;
          this.toggleSidenav('init');
        })
        .catch ((error: any) => {

        });
        this.workIsLoaded = true;
      },
      err =>  {

        console.log('loadLastWork ' + err.message);
      }
    );
  }

  async loadEquipments(): Promise<any> {

    let equipCur: LabelValue;
    const labeli18nPath = 'APPOINTMENT_DETAIL.JOB.COMPONENTS_LABEL.';
    if (this.wksWorks.wksMainEquipment !== null && this.wksWorks.wksMainEquipment !== undefined) {
      const equipItem = this.wksWorks.wksMainEquipment;
      equipCur = {
        label: this.translate.instant(labeli18nPath + equipItem.equipType) + ' - ' +
                  (equipItem.equipDesign ?
                    equipItem.equipDesign :
                    equipItem.equipName),
        value: equipItem.id,
        otherData: equipItem.equipSN
      };
      this.equipmentsList.push(equipCur);
    }
    if (this.wksWorks.wksSubEquipments !== null && this.wksWorks.wksSubEquipments.length > 0) {
      for (const equipItem of this.wksWorks.wksSubEquipments) {
        equipCur = {
          label: this.translate.instant(labeli18nPath + equipItem.equipType) + ' - ' +
                    (equipItem.equipDesign ?
                      equipItem.equipDesign :
                      equipItem.equipName),
          value: equipItem.id,
          otherData: equipItem.equipSN
        };
        this.equipmentsList.push(equipCur);
      }
    }

  }
  buildForm(): void  {
    this.photoDataForm = this.fb.group({
      equipSelect: this.fb.control(''),
      taskSelect: this.fb.control(''),
      sequenceSelect: this.fb.control(''),
    },
    {
      updateOn: 'blur'
    });
    this.subscribeFormChange();
  }
  subscribeFormChange(): void {

    this.photoDataForm.valueChanges.subscribe(
      result => {
        switch (this.photoDataForm.status) {
          case 'VALID':

            break;
          case 'INVALID':
            this.displayMenuAccess = false;
            break;
        }
      }
    );
  }
  toggleSidenav(arg: string): void {

    if (arg === 'backdrop') {
      return;
    }
    if (arg === 'init') {
      this.sidenavCollapsed = false;
      try {
        this.sidenav.open();
      } catch (error) {
      } finally { }
      return;
    }
    if (arg === 'formValid') {
      this.displayMenuAccess = true;
    }
    this.sidenavCollapsed = !this.sidenavCollapsed;
    if (this.sidenavCollapsed) {
      try {
        this.sidenav.close();
      } catch (error) {
      } finally { }
    } else {
      try {
        this.sidenav.open();
      } catch (error) {
      } finally { }
    }
  }
  onChangeEquip(equipId: string): void {
    // console.log('onChangePhotoEquipment : ' + equipId);
    this.photoData = {
      workId: this.wksWorks.id,
      equipId: undefined,
      taskId: undefined,
      sequenceAtt: undefined
    };
    for (const itemEquip of this.equipmentsList) {
      if (itemEquip.value === equipId) {
        this.selectedEquipLabel = itemEquip.label;
      }
    }
    this.photoDataForm.controls.taskSelect.setValue(undefined);
    this.photoDataForm.controls.sequenceSelect.setValue(undefined);

    this.photoData.equipId = equipId;
    this.photoDataBase64 = btoa(JSON.stringify(this.photoData));

    this.tasksList = [];
    for (const itemTask of this.workTasksContent) {
      let isOK = false;
      const taskTmp = JSON.parse(JSON.stringify(itemTask));
      const taskactionContent = taskTmp.taskActionContent;
      if (taskactionContent.equipments.all) {
        isOK = true;
      } else {
        for (const equipItem of taskactionContent.equipments.equipId) {
          if (equipItem === equipId)  {
            isOK = true;
          }
        }

      }
      if (isOK) {
        const taskItem: LabelValue = {
          label: taskTmp.taskrefLabel,
          value: taskTmp.id
        };

        this.tasksList.push(taskItem);
      }
    }
    this.tasksList.sort((obj1: LabelValue, obj2: LabelValue) => {
      return obj1.label > obj2.label ? 1 : -1;
    });
    const optBlank: LabelValue = {
      value: undefined,
      label: ''
    };
    this.tasksList.unshift(optBlank);
  }
  onChangeTask(taskId: string): void {
    this.photoData.taskId = taskId;
    this.photoDataBase64 = btoa(JSON.stringify(this.photoData));
  }
  onChangeSequence(sequenceAtt: string): void {
    this.photoData.sequenceAtt = sequenceAtt;
    this.photoDataBase64 = btoa(JSON.stringify(this.photoData));
  }
}
