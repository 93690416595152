import { Component, OnInit, HostListener, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ThemeService } from './services/theme.service';
import { AppointmentService } from './services/appointment.service';
import { AuthService } from './services/auth.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
// SharingDataService
import { SharingDataService } from './services/sharing-data.service';
import { WorksService } from './services/works.service';
import { environment } from '../environments/environment';
import { ModalCommonComponent } from './components/common/modal-common/modal-common.component';
import { WksEntityParams, WksEntity, WksEntityImg, InputDirection, LocalAddress, FileToDisplay, LabelValue, WksWorksModel } from './models';

import { WksUuidLinksModel } from './models/works/wks-common.model';
import { GlobalEventsManagerService } from './services/global-events-manager.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})

export class AppComponent implements OnInit {

  private readonly onDestroy = new Subject<void>();

  themeIsDark: boolean ;
  entityLoaded: boolean;
  entityError: boolean;
  wksEntityParams: WksEntityParams;
  wksEntity: WksEntity;
  wksLogoHome: WksEntityImg;
  wksLogoOtherPages: WksEntityImg;
  wksLogoHomeTitle: WksEntityImg;
  countryFmt: any;
  logoTitleToShow: any;
  isAppointment: boolean;
  isWork: boolean;
  displayWorkHome: boolean;
  displayWksHome: number;
  displayApptmntDetail: number;
  doCreateAccount: boolean;
  doLoginAccount: boolean;
  isPlanning: boolean;
  isQuotation: boolean;
  isCustomerEmailVerify: boolean;
  isLinkPhotos: boolean;
  displayPlanning: boolean;
  displayQuotation: boolean;
  verifyEmail: boolean;
  displayAppointmentDetail: boolean;
  doAuthenticateUser: boolean;
  changePassword: boolean;
  corporateName: string;
  authenticationFailed: boolean;
  credentials = {
    login: '',
    password: ''
  };

  currentLanguage: string;
  browserLang: string;
  optionItem: string;
  usersLanguages: any[];

  encodedReference: string;
  versionFE: string;
  versionBE: string;
  buildTimestampFE: string;
  versionDateBE: string;
  testScannerQrCodeV1: boolean;
  testScannerQrCodeV2: boolean;
  testSnapshotV1: boolean;
  testSnapshotV2: boolean;
  testWorkPhotos: boolean;
  callOrigin: string;
  /*
  scrHeight: string;
  scrWidth: string;
  sizeWidth: number;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any): void {
        this.scrHeight = window.innerHeight + 'px';
        this.scrWidth = window.innerWidth + 'px';
        this.sizeWidth = window.innerWidth;
  }
  */
  constructor(public translate: TranslateService,
              private sharingDataService: SharingDataService,
              private worksService: WorksService,
              private themeService: ThemeService,
              private appointmentService: AppointmentService,
              private authService: AuthService,
              private router: Router,
              private globalEventsManagerService: GlobalEventsManagerService,
              private dialog: MatDialog) {
    // this.getScreenSize();
    // console.log('Window size ' + this.scrHeight, this.scrWidth);
    this.themeIsDark = false;
    translate.addLangs(['ar-KW', 'en-EN', 'fr-FR']);
    translate.setDefaultLang('en-EN');
    this.browserLang = translate.getBrowserLang();
   //  const langBrowserCur = browserLang.match(/en|fr|ar/) ? browserLang : 'en');

    const langCur = this.getLangCode(this.browserLang);

    translate.use(langCur);
    this.currentLanguage = translate.currentLang;
    this.versionFE = environment.version;
    this.buildTimestampFE = 'Date : ' + environment.buildTimestamp;
  }
  initAllBooleans(): void {
    this.entityLoaded = false;
    this.entityError = false;
    this.displayPlanning = false;
    this.displayQuotation = false;
    this.doCreateAccount = false;
    this.doLoginAccount = false;
    this.displayAppointmentDetail = false;
    this.isWork = false;
    this.isAppointment = false;
    this.isPlanning = false;
    this.isQuotation = false;
    this.displayWorkHome = false;
    this.doAuthenticateUser = false;
    this.changePassword = false;
    this.isCustomerEmailVerify = false;
    this.verifyEmail = false;
    this.testScannerQrCodeV1 = false;
    this.testScannerQrCodeV2 = false;
    this.testSnapshotV1 = false;
    this.testSnapshotV2 = false;
    this.testWorkPhotos = false;
    this.isLinkPhotos = false;
  }
  subscribeAll(): void {
    this.globalEventsManagerService.synchroEvents
    .pipe(takeUntil(this.onDestroy))
    .subscribe( event => {
      // 'validateQuote
      let eventString: string;
      const typeEvent = typeof event;
      if (typeEvent === 'string')  {
        eventString = String(event);
        if (eventString !== undefined) {
          if (eventString.startsWith('returnHome')) {
            this.returnHome();
          }
        }
      } else if (event) {
         console.log(typeEvent);
      }
    });
  }
  getLangCode(lang: string): string {
    let langCur = '';
    switch (lang) {
      case 'fr': {
        langCur = 'fr-FR';
        break;
      }
      case 'ar': {
        langCur = 'ar-KW';
        break;
      }
      default: {
        langCur = 'en-EN';
        break;
      }
    }
    return langCur;
  }
  getLangFlag(lang: string): string {
    let langCur = '';
    switch (lang) {
      case 'fr': {
        langCur = 'fr';
        break;
      }
      case 'ar': {
        langCur = 'ar';
        break;
      }
      default: {
        langCur = 'gb';
        break;
      }
    }
    return langCur;
  }
  ngOnInit(): void {
    this.initAllBooleans();
    this.subscribeAll();

    this.themeService.setDarkTheme(this.themeIsDark);
    const pathCur = window.location.href.split('?');

    if (pathCur.length > 1) {
      const argsArray = pathCur[1].split('&');

      const args = argsArray[0].split('=');
      // console.log('pathcur ' + pathCur[1] + ' args : ' + args.toString() + ' ' + args.length.toString() + ' arg1 : ' + args[1]);
      if (( args.length > 1  && args[1] === '') || (args.length === 1)) {
      // if (args.length === 1) {
        // const entityCur = pathCur[1].replace(/=/g, '');
        // this.authenticateEntity(entityCur);
        // this.isAppointment = true;
        if (args[0] === 'testScannerQrCodeV1' ) {
          this.testScannerQrCodeV1 = true;
        } else if (args[0] === 'testScannerQrCodeV2' ) {
          this.testScannerQrCodeV2 = true;
        } else if (args[0] === 'testWorkPhotos' ) {
          this.testWorkPhotos = true;
        } else if (args[0] === 'testSnapshotV1' ) {
          this.testSnapshotV1 = true;
        } else if (args[0] === 'testSnapshotV2' ) {
          this.testSnapshotV2 = true;
        } else  {
          this.displayWorkHome = true;
          this.isWork = true;
          this.displayWksHome = Math.random();
          this.authenticateEntity(args[0]);
        }
      } else {
        if (args[1] === 'planning' ) {
          this.isPlanning = true;
          this.doAuthenticateUser = true;
          this.authenticateEntity(args[0]);
        } else if (args[1] === 'quotation' ) {
          this.isQuotation = true;
          const argsQuotation = argsArray[1].split('=');
          this.encodedReference = argsQuotation[1];
          this.doAuthenticateUser = true;
          this.authenticateEntity(args[0]);
        } else if (args[1] === 'customerVerify' ) {
          this.isCustomerEmailVerify = true;
          const argLink = argsArray[1].split('=');
          this.encodedReference = argLink[1];
          this.doAuthenticateUser = true;
          this.authenticateEntity(args[0]);
        } else if (args[1] === 'linkPhotos' ) {
          this.isLinkPhotos = true;
          const argLink = argsArray[1].split('=');
          this.encodedReference = argLink[1];
          this.doAuthenticateUser = false;
          this.callOrigin = args[1];
          this.authenticateEntity(args[0]);
        } else {
          this.authenticationFailed = true;
          this.pageError('404');
        }
      }
    } else {
      this.authenticationFailed = true;
      this.pageError('404');
    }
  }
  authenticateEntity(entityCur: string): void {
    this.authenticationFailed = false;
    this.credentials.login = entityCur;
    this.credentials.password = environment.keycloakProp.entityAuth;
    this.authService.connectToken(this.credentials, true)
    .subscribe(
      (data)  => {
        // console.log(this.authService.getAccessToken());
        this.authService.setEntityHeaders(entityCur);
        this.paramsEnvInit(entityCur);
        // console.log('authenticateEntity OK ' );
      },
      () => {
        console.log('authenticateEntity KO ' );
        this.authenticationFailed = true;
        this.pageError('404');
      }
    );
  }
  paramsEnvInit(entityCur: string): void {
      this.loadEntity(entityCur)
      .then ((responseEntity: any) => {
        this.wksEntity = responseEntity.body;
        this.versionBE = this.wksEntity.versionBE;
        this.versionDateBE = 'Date : ' +  this.wksEntity.versionDateBE;
        this.sharingDataService.setWksEntity(this.wksEntity);
        this.corporateName = undefined;
        if ((this.wksEntity.entityAddress !== null) && (this.wksEntity.entityAddress !== undefined)) {
          const address = JSON.parse(this.wksEntity.entityAddress);
          if ((address !== null) && (address !== undefined) && (address.corporateName !== undefined)) {
            this.corporateName = address.corporateName;
          }
        }
        this.usersLanguages = [];
        if (this.wksEntity.otherData !== undefined) {
          const otherData = JSON.parse(this.wksEntity.otherData);
          const activityCur = (otherData.activity !== undefined ? otherData.activity : 'workshop');
          if (this.wksEntity.entityOptions !== undefined && this.wksEntity.entityOptions !== null) {
            const entityOptions = JSON.parse(this.wksEntity.entityOptions);
            if (entityOptions.notificationsApp !== undefined) {
              this.sharingDataService.setNotifApp(entityOptions.notificationsApp);
            } else {
              this.sharingDataService.setNotifApp(undefined);
            }
          }
          this.loadWksParams();
          this.wksEntity.activity = activityCur;
          if (otherData.usersLanguages  !== undefined) {
            for (const langKey of otherData.usersLanguages) {
              const langKeyCur: string = langKey;
              if (this.isPlanning && langKey === 'ar') {
                continue;
              }
              const langKeyEntity = this.wksEntity.entityCountry.toLowerCase();
              const langCode = this.getLangCode(langKey);
              const langCur = {
                lang: langKey,
                langFile: langCode,
                langFlag: (langCode.toLowerCase().indexOf(langKeyEntity) > -1 ? langKeyEntity : this.getLangFlag(langKey) ),
                langDisplay: 'inline',
              };
              this.usersLanguages.push(langCur);
            }
          }
        }
        this.settingInputDirection(this.currentLanguage);
        this.settingLangsButtons(this.currentLanguage);
        this.loadLocalDef(this.wksEntity.entityCountry)
        .then ((loadLocalDef: any) => {
          const localDef = loadLocalDef.body;
          this.wksEntity.localAddress = localDef.localAddress;
          this.wksEntity.telFmt = localDef.telephoneDef;
          this.wksEntity.mooring = localDef.mooring;
          this.wksEntity.wintering = localDef.wintering;
          this.wksEntity.locales = localDef.locales;
          this.loadIntFormats();
          this.loadCountryFmt(this.wksEntity.entityCountry)
          .then ((loadCountryFmt: any) => {
            // this.countryFmt = JSON.parse(loadCountryFmt.body);
            this.countryFmt = loadCountryFmt.body;
            this.sharingDataService.setCountryFmt(this.countryFmt);
            this.loadEntityParams(entityCur)
            .then ((responseParams: any) => {
              this.wksEntityParams = responseParams.body;
              this.sharingDataService.setWksEntityParams(this.wksEntityParams);
              this.loadLogo(this.wksEntityParams.images.logoHome)
              .then ((data: WksEntityImg) => {
                this.wksLogoHome = data;
                this.wksLogoHome.width = this.wksEntityParams.images.logoHome.width;
                this.wksLogoHome.height = this.wksEntityParams.images.logoHome.heigth;
                this.sharingDataService.setWksEntityImg(this.wksLogoHome);
                if (this.wksEntityParams.images.logoHomeTitle === undefined) {
                  this.entityLoaded = true;
                } else {
                  this.loadLogo(this.wksEntityParams.images.logoHomeTitle)
                  .then ((data1: WksEntityImg) => {
                    this.wksLogoHomeTitle = data1;
                    this.wksLogoHomeTitle.width = this.wksEntityParams.images.logoHomeTitle.width;
                    this.wksLogoHomeTitle.height = this.wksEntityParams.images.logoHomeTitle.heigth;
                    this.createImageFromBlob(this.wksLogoHomeTitle);
                    this.entityLoaded = true;
                  }); // logoHomeTitle
                }
              }); // logoHome
            }); // loadEntityParams
          }); // loadCountryFmt
        }); // loadLocalDef
      }) // loadentity
      .catch((error: any) => {
        console.error(error);
        this.entityError = true;
        this.pageError('404');
      });

   }
  toggleDarkTheme(event: any): void {
    this.themeIsDark = !this.themeIsDark;
    this.themeService.setDarkTheme(this.themeIsDark);
  }

  loadLocalDef(countryKey: string): any {
    return new Promise((resolve, reject) => {
      this.appointmentService.loadLocalDef(countryKey)
      .subscribe(
        data => {
          const response = data as unknown as LocalAddress;
          resolve(response);
        },
        err => {
          console.log('loadLocaldef' + err.message);
          if (err.status === 404) {
          }
          reject(err.status);
        }
      );
    });
  }
  loadCountryFmt(countryKey: string): any {
    return new Promise((resolve, reject) => {
      this.appointmentService.loadCountryFmt(countryKey)
      .subscribe(
        data => {
          const response = data;
          resolve(response);
        },
        err => {
          console.log('loadCountryFmt' + err.message);
          if (err.status === 404) {
          }
          reject(err.status);
        }
      );
    });
  }
  loadWksParams(): any  {
    this.appointmentService.loadWksParams()
    .subscribe(
      data => {
        const wksParams = data.body;
        // console.log('loadCountries ' + JSON.stringify(countries));
        this.sharingDataService.setWksParams(wksParams, this.wksEntity.activity);
        this.sharingDataService.setPhonesType(wksParams.communications.phonesType);
      },
      err => {
        console.log('loadWksParams' + err.message);
        if (err.status === 404) {
        }

      }
    );
    this.worksService.loadCountries()
    .subscribe(
      data => {
        const countries = data.body;
        // console.log('loadCountries ' + JSON.stringify(countries));
        this.sharingDataService.setCountries(JSON.stringify(countries));
        this.worksService.loadAnchorages()
        .then ((anchorageList: any) => {
          // console.log('loadAnchorages : loaded ');
        }) // end loadAnchorages
        .catch((error: any) => {
          console.log('loadAnchorages : ' + error.message);
          return;
        }); // end loadAnchorages
      },
      err =>  {
        console.log('loadCountries ' + err.message);
      }
    );

  }
  loadEntity(entityCur: string): any {
    return new Promise((resolve, reject) => {
      this.appointmentService.getEntity(entityCur)
      .subscribe(
        data => {

          const response = data as WksEntity;
          resolve(response);
        },
        err => {
          console.log('loadentity' + err.message);
          if (err.status === 404) {
          }
          reject(err.status);
        }
      );
    });
  }
  loadEntityParams(entityCur: string): any {
    return new Promise((resolve, reject) => {
      this.appointmentService.getEntityParams(entityCur)
      .subscribe(
        data => {
          const response = data ;
          resolve(response);
        }, err => {
          console.log('loadParams' + err.message);
          if (err.status === 404) {
          }
          reject(err.status);
        }
      );
    });
  }
  loadIntFormats(): void  {
    this.appointmentService.loadIntFormats()
    .subscribe(
      data => {
        const formatsResponse = data.body;
        this.sharingDataService.setIntFormats(formatsResponse);
      },
      err =>  {
        console.log('loadIntFormats ' + err);
      }
    );
  }
  loadLogo(path: any): any {

    let pathCur: string;
    let fileExtension: string;
    if (path.fileName) {
      pathCur = path.fileName;
      fileExtension = path.fileName.split('.').pop();
    } else {
      pathCur = path;
      fileExtension = path.split('.').pop();
    }

    const mimeApp =  this.appointmentService.getTypeMime(fileExtension);
    return new Promise((resolve, reject) => {
      this.appointmentService.downloadImg(pathCur, this.wksEntity.stdEntity, mimeApp, fileExtension)
      .subscribe(
        (retMes: any) => {
          const wksEntityImgCur = retMes.body;
          // const blob = new Blob([new Uint8Array(response.fileContent)], { type: mimeApp });
          const blob = new Blob([wksEntityImgCur.fileContent], { type: mimeApp });
          wksEntityImgCur.blobCur = blob;
          resolve(wksEntityImgCur);
        }, err => {
          reject(err);
        }
      );
    });
  }
  pageError(pageNum: string): void {
    this.router.navigate(['/' + pageNum]);
  }
  settingInputDirection(lang: string): void {

    for (const curLang of environment.directionInput) {
      if (curLang.lang === lang) {
        this.wksEntity.directionInput = curLang.direction;
        break;
      }
    }
  }
  settingLangsButtons(langFileCur: string): void {
    for (const langCur of this.usersLanguages) {
      if (langCur.langFile === langFileCur) {
        langCur.langDisplay = 'none';
      } else  {
        langCur.langDisplay = 'inline';
      }
    }
  }
    // https://stackblitz.com/edit/angular-1yr75s?file=src%2Fapp%2Fapp.component.ts
  createImageFromBlob(image: WksEntityImg): void {
    // this.logoToShow = 'data:image/jpeg;base64,' + image.fileContent;
    this.logoTitleToShow = 'data:' + image.fileMediaType + ';base64,' + image.fileContent;
  }
  loginResult(loginOK: boolean): void {
    if (loginOK) {
      this.doAuthenticateUser = false;
      this.doLoginAccount = false;
      this.isAppointment = false;

      this.paramsEnvInit(this.authService.getUserLogged().entity);
      if (this.isPlanning) {
        this.displayPlanning = true;
      }
      if (this.isQuotation) {
        this.displayQuotation = true;
      }
      if (this.isCustomerEmailVerify) {
        this.verifyEmail = true;
      }
      if (this.isWork) {
        this.displayAppointmentDetail = true;
        this.displayApptmntDetail  = Math.random();
      }
    } else {
      this.doAuthenticateUser = true;
      // this.pageError('404');
    }
  }

  loginAccount(arg: string): void {
    this.doCreateAccount = false;
    this.doLoginAccount = false;
    if (arg === 'createAccount') {
      this.doCreateAccount = true;
    }
    if (arg === 'authenticateCustomer') {
      this.optionItem = 'authenticateCustomer';
      this.doLoginAccount = true;
    }

  }
  loginAuthenticateAccount(arg: string): void {
    this.doLoginAccount = false;
    this.doAuthenticateUser = false;
    this.displayWorkHome = true;
    this.displayWksHome = Math.random();
    if (arg === 'changePassword') {
      this.changePassword = true;
      this.displayWorkHome = false;
      // this.router.navigate(['/updatePwd', this.optionItem]);
    }
  }
  returnCreate(arg: string): void {
    this.doCreateAccount = false;
    this.doLoginAccount = false;
    this.displayWorkHome = true;
    this.displayWksHome = Math.random();
    this.entityLoaded = true;
  }
  returnAppointmentDetail(arg: string): void {
    this.doCreateAccount = false;
    this.doLoginAccount = false;
    this.displayWorkHome = true;
    this.displayWksHome = Math.random();
    this.displayAppointmentDetail = false;
    this.entityLoaded = true;
  }
  updatePwdEvent(arg: any): void {

    if (arg === 'exitUpdatePwd' || arg === 'pwdUpdated' ) {
      this.changePassword = false;
      this.displayWorkHome = true;
      this.displayWksHome = Math.random();
      this.entityLoaded = true;
    }

  }

  returnHome(): void {
    if (this.isPlanning){
      this.displayPlanning = false;
      this.doAuthenticateUser = true;
    }
    if (this.isQuotation){
      this.displayQuotation = false;

      this.doAuthenticateUser = true;
    }
  }
  displayMessageBox(titleBox: string, messageBox: string, messageTypeArg: string,
                    typeDialogArg: string, actionCur: string, dataArg: any): void {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: titleBox,
      typeDialog: typeDialogArg,
      panelClass: 'stdTheme',
      contentMessage: messageBox,
      data1: '',
      data2: '',
      messageType: messageTypeArg

      };

    const dialogRef = this.dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        // console.log('Dialog output:', data);
        if (( data === 'okAction') && actionCur === 'confirmChangeStatut') {

        }
      });

  }
}
