/*
https://stackblitz.com/edit/angular-webcam-snapshot-taken?file=src%2Fapp%2Fwebcam-snapshot%2Fwebcam-snapshot.component.ts
*/
import { AfterViewChecked, AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, OnChanges, SimpleChanges  } from '@angular/core';
import { WebcamUtil } from '../webcam-snapshot-v2/util/webcam.util';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { take, takeUntil, throwIfEmpty } from 'rxjs/operators';

import { faImage } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faCameraRotate } from '@fortawesome/free-solid-svg-icons';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { PhotoData, WksWorksDocs, WksWorksModel } from 'src/app/models/works/wks-works.model';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { AppointmentService } from 'src/app/services/appointment.service';
import { CommonMethods } from '../../tools/commonMethods';
import { AuthService } from 'src/app/services/auth.service';
import { ReadFile } from 'src/app/models/works/wks-common.model';
import { ModalCommonComponent } from '../../../../components/common/modal-common/modal-common.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { WorksService } from 'src/app/services/works.service';

import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-webcam-snapshot-v1',
  templateUrl: './webcam-snapshot-v1.component.html',
  styleUrls: ['./webcam-snapshot-v1.component.less']
})
export class WebcamSnapshotV1Component implements OnInit, AfterViewInit, OnChanges {

  @Input() wksWorks: WksWorksModel;
  @Input() taskLabel: string;
  @Input() userName: string;
  @Input() userLang: string;
  @Input() photoDataBase64: string;

  filesDeposit: Array<ReadFile> = [];
  wksWorksDocs: Array<WksWorksDocs> = [];
  private readonly onDestroy = new Subject<void>();
  // WIDTH = 640;
  // HEIGHT = 480;
  faCameraRotate = faCameraRotate;
  faUpload = faUpload;
  faImage = faImage;
  faPlus = faPlus;
  // -------------------------------------------------------- Start reprise V2 : ngx-webcam */
  private  DEFAULT_VIDEO_OPTIONS: MediaTrackConstraints = {facingMode: 'environment'};
  private  DEFAULT_IMAGE_TYPE = 'image/jpeg';
  private  DEFAULT_IMAGE_QUALITY = 0.92;
  /** Flag to enable/disable camera switch. If enabled, a switch icon will be displayed if multiple cameras were found */
  public allowCameraSwitch = true;
  /** Defines the max width of the webcam area in px */
  public WIDTH = 640;
  /** Defines the max height of the webcam area in px */
  public HEIGHT = 480;
  /** available video devices */
  public availableVideoInputs: MediaDeviceInfo[] = [];
  public videoOptions: MediaTrackConstraints = this.DEFAULT_VIDEO_OPTIONS;
  /** Indicates whether the video device is ready to be switched */
  public videoInitialized = false;
  /** MediaStream object in use for streaming UserMedia data */
  private mediaStream: MediaStream = null;
  /** width and height of the active video stream */
  private activeVideoSettings: MediaTrackSettings = null;
  /** Index of active video in availableVideoInputs */
  private activeVideoInputIndex = -1;
  @ViewChild('video', { static: true }) private video: ElementRef<HTMLVideoElement>;
  /** Canvas for Video Snapshots */
  @ViewChild('canvas', { static: true }) private canvas: ElementRef<HTMLCanvasElement>;
  public multipleWebcamsAvailable = false;
  eventText = '';
  // --------------------------------------------------------- End reprise V2 : ngx-webcam

  // @ViewChild('sidenav', {static: false}) mSidenav: any;
  // @ViewChild('video' , {static: false}) public video: ElementRef;
  // @ViewChild('canvas' , {static: false}) public canvas: ElementRef;

  captures: string[] = [];
  error: any;
  isCaptured: boolean;
  isUploadInProgress = false;
  progress: { percentage: number } = { percentage: 0 };



  configModal = {
    class: 'modal-sm modal-dialog-centered',
    backdrop: true,
    ignoreBackdropClick: true,
    animated: true,
    size: 'sm'
  };
  snapShotForm: FormGroup;

  photoData: PhotoData;

  constructor(private appointmentService: AppointmentService,
              private authService: AuthService,
              private dialog: MatDialog,
              private fb: FormBuilder,
              private worksService: WorksService,
              private translate: TranslateService) { }
  ngOnInit(): void {

  }
  ngOnChanges(changes: SimpleChanges): void {

    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'wksWorks': {
            this.wksWorks = changes.wksWorks.currentValue;
            break;
          }
          case 'taskLabel': {
            this.taskLabel = changes.taskLabel.currentValue;
            break;
          }
          case 'userName': {
            this.userName = changes.userName.currentValue;
            break;
          }
          case 'userLang': {
            this.userLang = changes.userLang.currentValue;
            break;
          }
          case 'photoDataBase64': {
            this.photoDataBase64 = changes.photoDataBase64.currentValue;
            if (this.photoDataBase64 !== undefined) {
              const stringDecryted = atob(this.photoDataBase64);
              this.photoData = JSON.parse(stringDecryted);
            }
            break;
          }
        } // end switch
      } // end if
    }

    this.initData();
  }
  initData(): void {
    this.initVariables();
    this.buildForm();
  }

  ngAfterViewInit(): void {
    if (this.activeVideoInputIndex === -1){
    // ----------------------------------------------------- start V2 ngx-webcam
      this.detectAvailableDevices()
      .then ((devices: MediaDeviceInfo[]) => {
        // start video
        // this.switchToVideoInput(null);
        this.rotateVideoInput(true);
      })
      .catch((err: string) => {
        // this.initError.next(<WebcamInitError>{message: err});
        // fallback: still try to load webcam, even if device enumeration failed
        // this.switchToVideoInput(null);
        this.rotateVideoInput(true);
      });
    // ------------------------------------------------------- end V2 ngx-webcam
    }
  }
  initVariables(): void {
    this.filesDeposit = [];
    this.wksWorksDocs = [];
    this.captures = [];
    this.taskLabel = '';
  }
  buildForm(): void {
    this.snapShotForm = this.fb.group({
      inputDescription: this.fb.control('')
    });
  }

  // tslint:disable-next-line:typedef
  //   async ngAfterViewInit() {
  //   await this.setupDevices();

  // ----------------------------------------------------- start V2 ngx-webcam
  /**
   * Switches the camera-view to the specified video device
   */
  public switchToVideoInput(deviceId: string): void {
    this.videoInitialized = false;
    this.stopMediaTracks();
    this.initWebcam(deviceId, this.videoOptions);
  }
  /*
  * Reads available input devices
  */
  private detectAvailableDevices(): Promise<MediaDeviceInfo[]> {
    return new Promise((resolve, reject) => {
      WebcamUtil.getAvailableVideoInputs()
        .then((devices: MediaDeviceInfo[]) => {
          this.availableVideoInputs = devices;
          this.multipleWebcamsAvailable = (devices.length > 1 ? true : false);
          resolve(devices);
        })
        .catch(err => {
          this.availableVideoInputs = [];
          reject(err);
        });
    });
  }
  /*
   * Stops all active media tracks.
   * This prevents the webcam from being indicated as active,
   * even if it is no longer used by this component.
   */
    private stopMediaTracks(): void {
      if (this.mediaStream && this.mediaStream.getTracks) {
        // pause video to prevent mobile browser freezes
        this.video.nativeElement.pause();

        // getTracks() returns all media tracks (video+audio)
        this.mediaStream.getTracks()
          .forEach((track: MediaStreamTrack) => track.stop());
      }
    }
  /**
   * Init webcam live view
   */
  private initWebcam(deviceId: string, userVideoTrackConstraints: MediaTrackConstraints): void {
    const videoCur = this.video.nativeElement;
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {

      // merge deviceId -> userVideoTrackConstraints
      const videoTrackConstraints = this.getMediaConstraintsForDevice(deviceId, userVideoTrackConstraints);

      navigator.mediaDevices.getUserMedia({video: videoTrackConstraints} as MediaStreamConstraints)
        .then((stream: MediaStream) => {
          this.mediaStream = stream;
          videoCur.srcObject = stream;
          videoCur.play();

          this.activeVideoSettings = stream.getVideoTracks()[0].getSettings();
          const activeDeviceId: string = this.getDeviceIdFromMediaStreamTrack(stream.getVideoTracks()[0]);
          // JLG pas utile dans notre utilisation
          // this.cameraSwitched.next(activeDeviceId);

          // Initial detect may run before user gave permissions, returning no deviceIds. This prevents later camera switches. (#47)
          // Run detect once again within getUserMedia callback, to make sure this time we have permissions and get deviceIds.
          this.detectAvailableDevices()
            .then(() => {
              /*
              this.activeVideoInputIndex = activeDeviceId ? this.availableVideoInputs
                .findIndex((mediaDeviceInfo: MediaDeviceInfo) => mediaDeviceInfo.deviceId === activeDeviceId) : -1;
                */
              this.videoInitialized = true;
            })
            .catch(() => {
              // this.activeVideoInputIndex = -1;
              this.videoInitialized = true;
            });
        })
        .catch((err: DOMException) => {
           // JLG pas utile dans notre utilisation
          // this.initError.next(<WebcamInitError>{message: err.message, mediaStreamError: err});
          console.log('initWebcam catch error navigator : ' + JSON.stringify(err));
        });
    } else {
      // JLG pas utile dans notre utilisation
      // this.initError.next(<WebcamInitError>{message: 'Cannot read UserMedia from MediaDevices.'});
      console.log('initWebcam catch error if  : ' + 'Cannot read UserMedia from MediaDevices.');
    }
  }
  /*
   * Get MediaTrackConstraints to request streaming the given device
   * @param deviceId
   * @param baseMediaTrackConstraints base constraints to merge deviceId-constraint into
   * @returns
   */
  private getMediaConstraintsForDevice(deviceId: string, baseMediaTrackConstraints: MediaTrackConstraints): MediaTrackConstraints {
    const result: MediaTrackConstraints = baseMediaTrackConstraints ? baseMediaTrackConstraints : this.DEFAULT_VIDEO_OPTIONS;
    if (deviceId) {
      result.deviceId = {exact: deviceId};
    }

    return result;
  }
  /*
   * Tries to harvest the deviceId from the given mediaStreamTrack object.
   * Browsers populate this object differently; this method tries some different approaches
   * to read the id.
   * @param mediaStreamTrack
   * @returns deviceId if found in the mediaStreamTrack
   */
    private getDeviceIdFromMediaStreamTrack(mediaStreamTrack: MediaStreamTrack): string {
      if (mediaStreamTrack.getSettings && mediaStreamTrack.getSettings() && mediaStreamTrack.getSettings().deviceId) {
        return mediaStreamTrack.getSettings().deviceId;
      } else if (mediaStreamTrack.getConstraints && mediaStreamTrack.getConstraints() && mediaStreamTrack.getConstraints().deviceId) {
        const deviceIdObj: ConstrainDOMString = mediaStreamTrack.getConstraints().deviceId;
        return this.getValueFromConstrainDOMString(deviceIdObj);
      } else {
        return null;
      }
    }
  /*
   * Extracts the value from the given ConstrainDOMString
   * @param constrainDOMString
   */
  private  getValueFromConstrainDOMString(constrainDOMString: ConstrainDOMString): string {
    if (constrainDOMString) {
      if (constrainDOMString instanceof String) {
        return String(constrainDOMString);
      } else if (Array.isArray(constrainDOMString) && Array(constrainDOMString).length > 0) {
        return String(constrainDOMString[0]);
      } else if (typeof constrainDOMString === 'object') {
        if (constrainDOMString.toString() === 'exact') {
          return 'exact';
        } else if (constrainDOMString.toString() === 'ideal') {
          return 'ideal';
        }
        /*
        if (constrainDOMString['exact']) {
          return String(constrainDOMString['exact']);
        } else if (constrainDOMString['ideal']) {
          return String(constrainDOMString['ideal']);
        }
          */
      }
    }

    return null;
  }
   /*
   * Returns the video aspect ratio of the active video stream
   */
   private getVideoAspectRatio(): number {
    // calculate ratio from video element dimensions if present
    const videoElement = this.video.nativeElement;
    if (videoElement.videoWidth && videoElement.videoWidth > 0 &&
      videoElement.videoHeight && videoElement.videoHeight > 0) {

      return videoElement.videoWidth / videoElement.videoHeight;
    }

    // nothing present - calculate ratio based on width/height params
    return this.WIDTH / this.HEIGHT;
  }
  /**
   * Event-handler for video resize event.
   * Triggers Angular change detection so that new video dimensions get applied
   */
  public videoResize(): void {
    // here to trigger Angular change detection
  }

  public getVideoWidth(): number {
    const videoRatio = this.getVideoAspectRatio();
    return Math.min(this.WIDTH, this.HEIGHT * videoRatio);
  }

  public getVideoHeight(): number {
    const videoRatio = this.getVideoAspectRatio();
    return Math.min(this.HEIGHT, this.WIDTH / videoRatio);
  }

  /*
   * Switches to the next/previous video device
   * @param forward
   */
  public rotateVideoInput(forward: boolean): void {
    if (this.availableVideoInputs && this.availableVideoInputs.length > 1) {
      /*
      const increment: number = forward ? 1 : (this.availableVideoInputs.length - 1);
      const nextInputIndex = (this.activeVideoInputIndex + increment) % this.availableVideoInputs.length;
      */
      let nextInputIndex = this.activeVideoInputIndex;
      if (this.activeVideoInputIndex < this.availableVideoInputs.length - 1) {
        nextInputIndex++;
      } else {
        nextInputIndex = 0;
      }
      this.activeVideoInputIndex = nextInputIndex ;
      this.switchToVideoInput(this.availableVideoInputs[nextInputIndex].deviceId);
    }
  }
  onSwipe(evt: any, idx: number): void {
    let x = '';
    let y = '';
    x = Math.abs(evt.deltaX) > 40 ? (evt.deltaX > 0 ? 'right' : 'left') : '';
    y = Math.abs(evt.deltaY) > 40 ? (evt.deltaY > 0 ? 'down' : 'up') : '';
    // this.eventText = `${x} ${y}<br/>`;
    if (y === 'down') {
      this.captures.splice(idx, 1);
      this.filesDeposit.splice(idx, 1);
      this.wksWorksDocs.splice(idx, 1);
    }

  }
  // ------------------------------------------------------- end V2 ngx-webcam
  // tslint:disable-next-line:typedef
  async setupDevices() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true
        });
        if (stream) {
          this.video.nativeElement.srcObject = stream;
          this.video.nativeElement.play();
          this.error = null;
        } else {
          this.error = 'You have no output video device';
        }
      } catch (e) {
        this.error = e;
      }
    }
  }

  capture(): void {
    this.drawImageToCanvas(this.video.nativeElement);
    const photoOrig = this.canvas.nativeElement.toDataURL('image/png');
    const photoBlob = this.DataURIToBlob(photoOrig);
    // const legend = this.snapShotForm.controls.inputDescription;

    let todayDateTime = CommonMethods.formatDate(new Date());
    todayDateTime = todayDateTime.replace(/[-]/g, '');
    todayDateTime = todayDateTime.replace(/[ ]/g, '_');
    todayDateTime = todayDateTime.replace(/[:]/g, '');
    const fileName = todayDateTime + '.png';
    const photoFile = this.convertBlobToFile(photoBlob, fileName);

    this.addFilesDeposit(todayDateTime, photoFile);
    this.captures.push(photoOrig);
    this.isCaptured = true;
  }
  addFilesDeposit(filename: string, photoFile: File): void {

    const readingFile: ReadFile = new ReadFile();
    readingFile.nameFile = filename;
    readingFile.sizeFile = photoFile.size;
    readingFile.typeFile = photoFile.type;
    readingFile.fileObject = photoFile;
    readingFile.legendFile = this.snapShotForm.controls.inputDescription.value ;
    readingFile.messageFile = '';
    readingFile.uploadedFile = false;
    readingFile.validatedFile = false;

    this.filesDeposit.push(readingFile);
    this.wksWorksDocs.push(this.fillModel(readingFile));
  }
  resetCurrent(): void {
    this.isCaptured = false;
  }

  setPhoto(idx: number): void {
    this.isCaptured = true;
    const image = new Image();
    image.src = this.captures[idx];
    this.drawImageToCanvas(image);
  }
  fillModel(photoCur: ReadFile): WksWorksDocs {
    const suffix =  photoCur.nameFile.split('.').pop();
    const localFmt = this.translate.instant('LOCAL_FMT.timeFormat');

    let filePropertiesTmp: any;
    const todayDateTime = CommonMethods.formatDate(photoCur.fileObject.lastModified);
    filePropertiesTmp = {
      lastModified: todayDateTime,
      lastModifiedDate: CommonMethods.dateToString(photoCur.fileObject.lastModified, 'date', localFmt),
      name: photoCur.fileObject.name,
      size: photoCur.fileObject.size,
      type: photoCur.fileObject.type,
    };

    const jsonData =  {
      fileProperties: filePropertiesTmp,
      photoData: this.photoData,
    };


    const wksDocCard: WksWorksDocs = {
      workId: this.wksWorks.id,
      stdEntity:  this.wksWorks.stdEntity,
      workdocLegend: photoCur.legendFile,
      workdocFilename: photoCur.nameFile,
      workdocType: 'photo',
      workdocJson: JSON.stringify(jsonData),
     };

    return wksDocCard;
  }
  DataURIToBlob(dataURI: string): Blob {
    const splitDataURI = dataURI.split(',');
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }
  convertBlobToFile(blobCur: Blob, fileName: string): File {
    const b: any = blobCur;
    // A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModified = new Date();
    b.name = fileName;
    //  Cast to a File() type
    return b as File;
  }
  drawImageToCanvas(image: any): void {
    this.canvas.nativeElement
      .getContext('2d')
      .drawImage(image, 0, 0, this.getVideoWidth(), this.getVideoHeight());
  }
  uploadPhotos(): void {
    let idxFile = 0;
    let isError = false;
/*
    let sequenceTime = CommonMethods.formatDate(new Date());
    sequenceTime = sequenceTime.replace(/[-]/g, '');
    sequenceTime = sequenceTime.replace(/[ ]/g, '_');
    sequenceTime = sequenceTime.replace(/[:]/g, '');
*/
    const sequenceDateInput = new Date();
    const sequenceTimeInput = { hour: new Date().getHours(), minute: new Date().getMinutes() };
    const sequenceTimeTmp = sequenceTimeInput.hour + ':' + sequenceTimeInput.minute;
    const sequenceDate = CommonMethods.dateToString(sequenceDateInput, 'date', environment.fmtDateBdd);
    const sequenceTime = sequenceDate + '_' + sequenceTimeTmp;

    for (const docCur of this.filesDeposit)  {
      const currentDoc = this.wksWorksDocs[idxFile];

      const titleBox = this.translate.instant('SNAPSHOT.titleBox');
      const messageBox = this.translate.instant('SNAPSHOT.UploadProcess', { fileCur: idxFile + 1 });
      this.displayMessageBox(titleBox, messageBox, 'INFO', 'infoProgressWks', 'htmlMessage', 'filesUploadProgress', undefined, undefined);
      this.uploadPhoto(docCur.fileObject, currentDoc, sequenceTime).then(
        (val: any) => {
          // console.log('UPLOAD OK ' + docCur.fileObject.name);
        },
        (error: any) => {
          // console.log('UPLOAD KO ' + docCur.fileObject.name + ' error : ' + error);
          isError = true;
        }
      ); // end then uploadFile
      idxFile++;
    }
    this.closeMessageBox();
    if (!isError) {
      const titleBox = this.translate.instant('SNAPSHOT.titleBox');
      const messageBox = this.translate.instant('SNAPSHOT.UploadSuccess', { nbPhoto: idxFile });
      this.displayMessageBox(titleBox, messageBox, 'INFO', 'infoWks', 'htmlMessage', 'filesUploadedSuccess', undefined, undefined);
    } else {
      const titleBox = this.translate.instant('SNAPSHOT.titleBox');
      const messageBox = this.translate.instant('SNAPSHOT.UploadFailed');
      this.displayMessageBox(titleBox, messageBox, 'WARNING', 'alertWks', 'htmlMessage', 'filesUploadFailed', undefined, undefined);
    }
  }

  private uploadPhoto(file: File, currentDoc: WksWorksDocs, sequenceTime: string): any {
    const userCur = this.userName;
    // console.log('fichier transféré ' + _file.name);
    return  new Promise<void>((resolve, reject) => {
      this.isUploadInProgress = true;
      this.appointmentService.uploadPhotosToStorageWksWorkdav(file, currentDoc,
              sequenceTime, 'add', userCur).subscribe((event) => {
          if (event instanceof HttpResponse) {
            console.log('File is completely uploaded!');
            this.isUploadInProgress = false;
            resolve();
          } else if (event.type === HttpEventType.UploadProgress) {
            this.progress.percentage = Math.round(100 * event.loaded / event.total);
            console.log('Upload ' + this.progress.percentage);
          }
        },
        response => {
          const numError = response.status;
          let message: string;
          switch (numError) {
            case 417:
              message = this.translate.instant('Duplicate file');
              console.log('Error  Message ' + response.message +
                    + 'Status ' + response.status + ' user message : ' + message);
              break;
            case 418:
              message = this.translate.instant('Error file');
              console.log('Error  Message ' + response.message +
                    + 'Status ' + response.status + ' user message : ' + message);
              break;
            default:
              message = this.translate.instant('Upload error file');
              console.log('Error  Message ' + response.message +
                      + 'Status ' + response.status + ' user message : ' + message);
              break;
            }

          this.isUploadInProgress = false;
          reject(message);
          } ,
        );
      });
  }
  displayMessageBox(titleBoxArg: any, messageBoxArg: any, messageTypeArg: string, typeDialogArg: string,
                    actionCurArg: string, origMessage: string, data1Arg: string, data2Arg: string): void {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: titleBoxArg,
      typeDialog: typeDialogArg,
      panelClass: 'stdTheme',
      contentMessage: messageBoxArg,
      data1: data1Arg,
      data2: data2Arg,
      messageType: messageTypeArg,
      actionCur: actionCurArg
    };

    const dialogRef = this.dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        if (origMessage === 'filesUploadedSuccess' ) {
          this.initVariables();
          this.resetCurrent();
        }
    });

  }
  closeMessageBox(): void {
    this.dialog.closeAll();
  }
}
