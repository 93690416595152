
import { EventDef, EventRh } from '../shared.model';

export class ItemWksMenu {
  id: string;
  itemLabel: string;
  itemParent: string;
  itemOrder: string;
  itemChild?: string;
  itemStatut: string;
  itemRoles: string;
  itemRoutingPath?: string;
  itemRoutingArgs?: string;
  rolesAllowed: string;
  rolesProhibited?: string;
  itemConstraints?: string;
  itemMenupage?: string;
}
export interface MenuWksModel {
  id: number;
  itemLabel: string;
  itemParent: string;
  itemOrder: string;
  itemChild: string;
  itemStatut: string;
  itemRoles: string;
  itemRoutingPath: string;
  itemRoutingArgs: string;
  rolesAllowed: string;
  rolesProhibited: string;
  itemConstraints: string;
  itemMenupage: string;
}

export interface TypesLabel {
  labelType: string;
  equipType: string;
  equipAttachedBoat: boolean;
  equipHoursService?: any;
}

export interface BrandWksModel {
  id?: number;
  stdEntity: string;
  brandRow: string;
  brandName: string;
  brandParam: BrandParam;
  equipType?: string;
  modelName?: string;
  stdCreation?: Date;
  stdLastupdate?: Date;
  stdDisable?: Date;

}
export interface WksUuidLinksModel {
  id?: string;
  wlReference: string;
  wlLifetime: number;
  wl_lifetime_unit: string;
  wlApplication: string;
  wlData: string;
  wlLinkApp: string;
  wlLinkRef: string;
  stdCreation?: Date;
  stdLastupdate?: Date;
  stdDisable?: Date;
}


export interface WksTmpData {
  id?: number;
  dataRef: string;
  dataCalc: string;
}
export interface LabelValue {
  label: string;
  value: any;
  otherData?: any;
}
export interface LabelInt {
  label: string;
  value?: number;
}
export interface MeasurementList {
  measurement: string;
  list: string[];
}

export interface UnitQuantity {
  unit: string;
  quantity: number;
}

export interface DataUpstream {
  equipType?: string;
  equipBrand?: string;
  equipModel?: string;
}

export interface TaskWksEvent {
  orig: string;
  todo: string;
  content: any;
}

export interface FormFieldFmt {
  pattern: string;
  displayFmt: string;
  displayUnfmt: string;
  fieldType: string;
  lenght: number;
  separator: string;
  upperCase: boolean;
}

export interface WksEntityParams {
  equipments?: EquipmentParam[];
  images?: {
    logoHome: any;
    logoOtherPages: any;
    logoHomeTitle: any;
  };
  ctrlInput?: {
    multiEquipment: boolean;
    inputSerialNumber: boolean;
    ctrlSerialNumber: boolean;
  };
  statuts?: Statuts[];
  supportMails: string[];
  dayHours: string[];
  weekStartsOn: number;
  weekendDays: number[];
  calendarEvents?: EventDef[];
  rhEvents?: EventRh[];
}
export interface Statuts {
  ranking: number;
  name: string;
  validating: string[];
}
export interface ValidatingField {
  fieldName: string;
  fieldType: string;
  fieldList?: string;
}
export interface EquipmentParam {
  type: string;
  brands: BrandParam[];
  services: string[];
  engines?: Hours;
}
export interface BrandParam {
  label: string;
  model: CustomParam;
  serialNumber: CustomParam;
}
export interface CustomParam  {
  holder: string;
  fmt: string;
  display: string;
  lenght: number;
  separator: string;
  upperCase: boolean;
  helpImg: string;
}
export interface Hours {
  hours: {
    min: number;
    max: number;
  };
}
export class ReadFile {
  nameFile: string;
  pathFile?: string;
  sizeFile: number;
  typeFile: string;
  rowsFile?: number;
  messageFile: string;
  fileObject: File;
  validatedFile: boolean;
  uploadedFile: boolean;
  legendFile?: string;
}
