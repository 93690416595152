<div [ngClass]="{'dark-theme': themeIsDark}">
    <mat-card class="stdTheme" *ngIf="okToDisplay">
        <mat-card-title style="text-align: center;" >{{ 'APPOINTMENTS.WELCOME' | translate }}</mat-card-title> 
        <mat-card-content fxLayoutAlign="center">
          <fieldset class="informationsborder">
            <div class="logo" >
              <img [src]="logoToShow | safeHtml: 'resourceUrl' "  [ngStyle]="{'width': wksLogoHome.width, 'height': wksLogoHome.height }" >
            </div>
          </fieldset>
        </mat-card-content>
        <mat-card-actions class="actions" >
            <button  mat-raised-button  (click)="authenticateCustomer()">
                {{ 'APPOINTMENTS.AUTHENTIFICATION' | translate }}
            </button>
            <div fxFlex></div>

            <button mat-raised-button (click)="createCustomer()">
                {{ 'APPOINTMENTS.NEW-ACCOUNT' | translate }}
            </button>
        </mat-card-actions>
    </mat-card>
</div>