import { Component, AfterViewInit , ElementRef, Renderer2, OnDestroy} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { Inject } from '@angular/core';

import { Subscription } from 'rxjs';

import { CommonReport, LevelType, AlertType } from '../../../models/shared.model';
import { CommonMethods } from '../tools/commonMethods';
import { SharingDataService } from '../../../services/sharing-data.service';

export class Group {
  level = 0;
  parent: Group;
  expanded = true;
  get visible(): boolean {
    return !this.parent || (this.parent.visible && this.parent.expanded);
  }
}

export class Line {
  parent: string;
  message: string;
}
export class ParentAttributes {
  parent: string;
  parentId: number;
  childId: number;
}

@Component({
  selector: 'app-modal-common',
  templateUrl: './modal-common.component.html',
  styleUrls: ['./modal-common.component.less']
})
export class ModalCommonComponent implements AfterViewInit, OnDestroy  {

  modalTitle: string;

  listErrors: any;
  modalSubTitle: string;
  isEndReport: boolean;
  reportIsStarted: boolean;
  // textHTML;
  displayStatus: string;
  eventOkToLaunch: string;
  subscription: Subscription;
  jsonData: any;
  nbParent = -1;

  uploadProcess: boolean;
  confirmAction: boolean;
  updatePwd: boolean;
  confirmAlert: boolean;
  newPasswd: string;
  newPasswdOK: boolean;
  displayMessages: any[] = [];
  numberMessages: number[] = [0];
  numberErrors: number[] = [0];
  mapParent = new Map();
// alert
  contentMessage: string;
  data1: string;
  data2: string;
  messageType: string;
  alertWks: boolean;
  infoWks: boolean;
  actionCur: string;
  infoProgressWks: boolean;

  constructor(private dialogRef: MatDialogRef<ModalCommonComponent>,
              @Inject(MAT_DIALOG_DATA) data: any,
              private sharingDataService: SharingDataService) {

      this.modalTitle = data.title;
      this.uploadProcess = false;
      this.confirmAlert = false;
      this.updatePwd = false;
      this.alertWks = false;
      this.infoWks = false;
      this.confirmAction = false;

      if (data.typeDialog === 'upload') {
        this.uploadProcess = true;
      }

      if (data.typeDialog === 'updatePwd') {
        this.updatePwd = true;
        this.newPasswdOK = false;
      }

      if (data.typeDialog === 'confirmAction') {
        this.confirmAction = true;
        this.contentMessage = data.contentMessage;
        this.data1 = data.data1;
        this.data2 = data.data2;
        this.messageType = data.messageType;
        if (data.actionCur) {
          this.actionCur = data.actionCur;
        }
      }
      if (data.typeDialog === 'infoProgressWks') {
        this.infoProgressWks = true;
        this.contentMessage = data.contentMessage;
        this.data1 = data.data1;
        this.data2 = data.data2;
        this.messageType = data.messageType;
        if (data.actionCur) {
          this.actionCur = data.actionCur;
        }
      }
      if (data.typeDialog === 'alertWks') {
        this.confirmAlert = true;
        this.alertWks = true;
        this.contentMessage = data.contentMessage;
        this.data1 = data.data1;
        this.data2 = data.data2;
        this.messageType = data.messageType;
        if (data.actionCur) {
          this.actionCur = data.actionCur;
        }
      }
      if (data.typeDialog === 'infoWks') {
        this.confirmAlert = true;
        this.infoWks = true;
        this.contentMessage = data.contentMessage;
        this.data1 = data.data1;
        this.data2 = data.data2;
        this.messageType = data.messageType;
        if (data.actionCur) {
          this.actionCur = data.actionCur;
        }
      }
      this.eventOkToLaunch = data.eventOkToLaunch;
      this.subscription = this.sharingDataService.commonReportSubject.subscribe((event: CommonReport) => {
        if (event instanceof CommonReport) {
          console.log('ModalCommonComponent :commonReportSubject  ' + event.toString());
          this.applyEvent(event);
        }
      });
    }
    private applyEvent(event: CommonReport): void {
      const commonMessage: CommonReport = event;
      let tmpParent: ParentAttributes;
      if (commonMessage.isEndReport) {
        this.isEndReport = true;
        this.reportIsStarted = false;
      } else
      if (commonMessage.isStartReport) {

        this.reportIsStarted = true;
        this.jsonData = JSON.parse('[ ]');
        this.isEndReport = false;
        tmpParent = new ParentAttributes();
        tmpParent.parentId = -1;
        tmpParent.childId = -1;
      } else {
        if (commonMessage.isNewParent) {
          this.nbParent++;
          tmpParent = new ParentAttributes();
          tmpParent.parentId = this.nbParent;
          tmpParent.childId = -1;
          this.mapParent.set(commonMessage.pathParent, tmpParent);
          this.numberMessages[tmpParent.parentId] = 0;
          this.numberErrors[tmpParent.parentId] = 0;
          this.displayMessages[tmpParent.parentId] = false;

          const jsonTmp = `{ "parent": "${commonMessage.id}","children": [ ]}`;
          this.jsonData = CommonMethods.jsonAddNode(this.jsonData, false, jsonTmp, tmpParent.parentId);

        } else if (commonMessage.level === LevelType.child) {
          tmpParent = this.mapParent.get(commonMessage.pathParent);
          tmpParent.childId++;
          if (commonMessage.type === AlertType.Error) {
            this.numberErrors[tmpParent.parentId]++;
          }
          this.mapParent.set(commonMessage.pathParent, tmpParent);
          this.numberMessages[tmpParent.parentId]++;
          const jsonTmp = `{ "parent": "${commonMessage.pathParent}", "message": "${commonMessage.message}" }`;
          this.jsonData[tmpParent.parentId].children =
                        CommonMethods.jsonAddNode(this.jsonData[tmpParent.parentId].children, false, jsonTmp, tmpParent.childId);
        }
        /* console.log('Parent / Child : ' + tmpParent.parentId.toString() + '/' + tmpParent.childId.toString() +
                  ' JSON ' + JSON.stringify(this.jsonData));*/
      }

    }
    ngAfterViewInit(): void {

      this.sharingDataService.synchroEvents.next(this.eventOkToLaunch);
    }
    ngOnDestroy(): void {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    }
    onNoClick(): void {
      if (this.confirmAction) {
        this.sharingDataService.synchroEvents.next('cancelAction');
        this.dialogRef.close('cancelAction');
      } else {
        this.sharingDataService.synchroEvents.next('cancelModal');
      }
    }
    onOKClick(): void {
      if (this.updatePwd) {
        this.sharingDataService.synchroEvents.next('newPwd:' + this.newPasswd);
      } else {
        this.sharingDataService.synchroEvents.next('okAction');
        this.dialogRef.close('okAction');
      }
    }

    loadInit(): void {
    }
    toggleMessages(event: any): any {
      return this.displayMessages[event] = ! this.displayMessages[event];
    }

  toggleRow(event: any): void {
  }

  close(): void {
      this.dialogRef.close();
    }
}
