<div class="container-fluid" *ngIf="workIsLoaded"  style="overflow-x: hidden!important; overflow-y: auto!important; height: 100%!important" >
    <mat-toolbar class="photos-toolbar">
        <button mat-icon-button (click)="toggleSidenav('')" [disabled]="!displayMenuAccess"><mat-icon>menu</mat-icon></button>
        <span>{{ selectedEquipLabel}}</span>
        <!--<h1 class="example-app-name">Responsive App</h1>-->
      </mat-toolbar>
    <mat-sidenav-container (backdropClick)="toggleSidenav('backdrop')" [hasBackdrop]="hasBackdrop" fxFlexFill 
                class="photos-sidenav-container w-100" autosize>
        <mat-sidenav #sidenav fxLayout="column" mode="over" opened="true" position="end" class="lateral-sidenav-90" disableClose style="overflow:hidden !important">
            <div class="container-fluid"  style="overflow-x: hidden!important; overflow-y: auto!important; height: 100%!important; max-height: 100%!important;" >
                <form [formGroup]="photoDataForm"  style="margin: 0px; height: 100%; width: 100%;" >
                    <mat-card>
                        <mat-card-header>
                            <mat-card-title style="margin-top: 10px;text-align: center;">{{ 'WORK_PHOTOS.ChooseWorkSequence' | translate }}</mat-card-title>
                          </mat-card-header>
                        <mat-card-content>
                            <div class="flex-row row w-100"> 
                                <div class="col-12">
                                    <mat-form-field appearance="standard"  style="width: 100%!important;">
                                        <mat-label>{{ 'WORK_PHOTOS.equipSelect' | translate }}</mat-label>
                                        <mat-select id="language" placeholder="{{ 'WORK_PHOTOS.ChooseEquipment' | translate }}" formControlName="equipSelect" 
                                                #equipSelect required
                                                (ngModelChange)="onChangeEquip(equipSelect.value)"
                                                ngDefaultControl >
                                                <mat-option class="selectOptionsTheme" *ngFor="let equipItem of equipmentsList" 
                                                            [value]="equipItem.value"  >{{ equipItem.label }}</mat-option>
                                        </mat-select>         
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="flex-row row w-100"> 
                                <div class="col-12">
                                    <mat-form-field appearance="standard"  style="width: 100%!important;">
                                        <mat-label>{{ 'WORK_PHOTOS.taskSelect' | translate }}</mat-label>
                                        <mat-select id="language" placeholder="{{ 'WORK_PHOTOS.ChooseTask' | translate }}" formControlName="taskSelect" 
                                                #taskSelect required
                                                (ngModelChange)="onChangeTask(taskSelect.value)"
                                                ngDefaultControl >
                                                <mat-option class="selectOptionsTheme" *ngFor="let taskItem of tasksList" 
                                                            [value]="taskItem.value"  >{{ taskItem.label }}</mat-option>
                                        </mat-select>         
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="flex-row row w-100"> 
                                <div class="col-12">
                                    <mat-form-field appearance="standard"  style="width: 100%!important;">
                                        <mat-label>{{ 'WORK_PHOTOS.sequenceSelect' | translate }}</mat-label>
                                        <mat-select id="language" placeholder="{{ 'WORK_PHOTOS.ChooseSequence' | translate }}" formControlName="sequenceSelect" 
                                                #sequenceSelect required
                                                (ngModelChange)="onChangeSequence(sequenceSelect.value)"
                                                ngDefaultControl >
                                                <mat-option class="selectOptionsTheme" *ngFor="let sequenceItem of sequenceAttList" 
                                                            [value]="sequenceItem.value"  >{{ sequenceItem.label }}</mat-option>
                                        </mat-select>         
                                    </mat-form-field>
                                </div>
                            </div>
                        </mat-card-content>
                        <mat-card-actions style="margin: 0px; padding: 0px; width: 100%">
                            <div class="flex-row row stdTheme d-flex justify-content-center" style="margin-left: 0px; margin-right: 0px; height: 20px;">
                                <div class="d-flex justify-content-center">
                                    <button mat-icon-button class="buttonTheme" [disabled]="photoDataForm.invalid"
                                        style="margin-left: 0px;margin-right: 0px;height:20px !important;width:20px !important;font-size:20px !important;" 
                                        (click)="toggleSidenav('formValid')">
                                        <span class="fa fa-check" style="font-size: 30px" ></span>
                                    </button>
                                </div>
                            </div>
                        </mat-card-actions>
                    </mat-card>
                </form>
            </div>
        </mat-sidenav>
        <mat-sidenav-content style="padding: 0px">
            <app-webcam-snapshot-v1
                [wksWorks]="wksWorks" 
                [taskLabel]="taskLabel"
                [userName]="userName"
                [userLang]="userLang"
                [photoDataBase64]="photoDataBase64"
            >
            </app-webcam-snapshot-v1>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
