import { Component, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, Input } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from '../../../../services/theme.service';
import { SharingDataService } from '../../../../services/sharing-data.service';

import { WksEntityParams, WksEntity, WksEntityImg } from '../../../../models/shared.model';



@Component({
  selector: 'app-wks-home',
  templateUrl: './wks-home.component.html',
  styleUrls: ['./wks-home.component.less']
})
export class WksHomeComponent implements OnChanges {
  @Input() displayWksHome: number;
  @Output()
  optionEvent = new EventEmitter<string>();

  wksEntityParams: WksEntityParams;
  wksEntity: WksEntity;
  wksLogoHome: WksEntityImg;
  countryFmt: any;

  logoToShow: any;
  okToDisplay: boolean;
  themeIsDark: boolean;

  constructor(private themeService: ThemeService,
              private translateService: TranslateService,
              private sharingDataService: SharingDataService,
              private router: Router) { }
  ngOnChanges(changes: SimpleChanges): void {

    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'displayWksHome': {
            // tslint:disable-next-line:no-string-literal
            this.displayWksHome = changes['displayWksHome'].currentValue;
            this.okToDisplay = true;
            break;
          }
        } // end switch
      } // end if
    }
    this.initData();
  }
  initData(): void {
    this.initBooleans();
    this.themeIsDark = this.themeService.isDarkTheme();
    this.themeService.setDarkTheme(this.themeIsDark);
    this.initParams();
  }
  initBooleans(): void {
    this.themeIsDark = false;
    this.okToDisplay = false;
  }
  initParams(): void {
    this.countryFmt = this.sharingDataService.getCountryFmt();
    this.wksEntityParams = this.sharingDataService.getWksEntityParams();
    this.wksEntity = this.sharingDataService.getWksEntity();
    this.wksLogoHome = this.sharingDataService.getWksEntityImg();

    this.modifImgSize();
    this.createImageFromBlob(this.wksLogoHome);
    this.okToDisplay = true;
  }
  createImageFromBlob(image: WksEntityImg): void {
    // this.logoToShow = 'data:image/jpeg;base64,' + image.fileContent;
    this.logoToShow = 'data:' + image.fileMediaType + ';base64,' + image.fileContent;
  }
  modifImgSize(): void {
    const widthCur = window.innerWidth - 20;
    const widthOrig = Number.parseInt(this.wksLogoHome.width, 10);
    // console.log(widthCur);
    if (widthOrig > widthCur) {
      const coefWidth = widthCur / widthOrig;
      const newWidth = widthOrig * coefWidth;
      const newHeight =  Number.parseInt(this.wksLogoHome.height, 10) * coefWidth;
      this.wksLogoHome.height = newHeight.toString() + 'px';
      this.wksLogoHome.width = newWidth.toString() + 'px';
    }
  }
  authenticateCustomer(): void {
    this.okToDisplay = false;
    this.optionEvent.emit('authenticateCustomer');
  }
  createCustomer(): void {
    this.okToDisplay = false;
    // this.router.navigate(['/createAccount']);
    this.optionEvent.emit('createAccount');
  }
}
