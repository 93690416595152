
<!--
<h3 class="modal-title"><strong>{{ 'CALENDAR_EVENT.eventDetail' | translate }}</strong>
    <span  *ngIf="workIsLoaded" >
        {{workCur.workRef }}
    </span>
    <span  *ngIf="!workIsLoaded" >
        <mat-progress-spinner [mode]="'indeterminate'"  diameter="40"></mat-progress-spinner>
        <br>
        <span class="blink_text">Please wait...</span>
    </span>
</h3>
-->
<span  *ngIf="!workIsLoaded" >
    <mat-progress-spinner [mode]="'indeterminate'"  diameter="40"></mat-progress-spinner>
    <br>
    <span class="blink_text">Please wait...</span>
</span>
<div *ngIf="workIsLoaded"  mat-dialog-title class="stdTheme" style="margin: 0px!important">

    <mat-toolbar  class="stdTheme">
        <mat-toolbar-row style="height: 30px">
            <button mat-icon-button (click)="toggleSidenav()" fxShow="true" fxHide.gt-sm>
                <mat-icon>menu</mat-icon>
            </button>
            <span class="titleWork">{{ workCur.workRef }} {{workCur.workObject}}</span>
            <span class="menu-spacer"></span>
            <div fxShow="true" fxHide.lt-md>
                <!-- The following menu items will be hidden on both SM and XS screen sizes -->
                <a mat-button class="buttonTheme" (click)="choose('customer')">{{ 'WORK_CARD.MENU.CUSTOMER' | translate }}</a>
                <a mat-button class="buttonTheme" (click)="choose('contacts')">{{ 'WORK_CARD.MENU.CONTACTS' | translate }}</a>
                <a mat-button class="buttonTheme" (click)="choose('location')">{{ 'WORK_CARD.MENU.LOCATION' | translate }}</a>
                <a mat-button class="buttonTheme" (click)="choose('photos')">{{ 'WORK_CARD.MENU.PHOTOS' | translate }}</a>
                <a mat-button class="buttonTheme" (click)="choose('mainEquip')">{{ 'WORK_EQUIPMENT.' + workCur.wksMainEquipment.equipType | translate }}</a>
            </div>
        </mat-toolbar-row>
        <mat-toolbar-row style="height: 30px" >
            <div fxShow="true" fxHide.lt-md *ngIf="subEquipments !== undefined && subEquipments.length > 0">
                <a *ngFor="let item of subEquipments">
                    <button class="buttonTheme" mat-button (click)="choose('subEquip', item)">{{ item.equipDesign | translate }}</button>
                </a>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>
</div>
<mat-dialog-content *ngIf="workIsLoaded"  class="stdTheme" style="padding: 0px!important; margin: 0px!important;">

    <div *ngIf="workIsLoaded" style="height: 60vh!important;">

        <mat-sidenav-container fxFlexFill (backdropClick)="toggleBackdropSidenav()" class="stdTheme">
          <mat-sidenav #sidenav>
            <mat-nav-list >
              <a class="stdTheme" (click)="choose('')" mat-list-item>{{ 'WORK_CARD.MENU.CLOSE' | translate }}</a>
              <a class="stdTheme" mat-list-item (click)="choose('customer')">{{ 'WORK_CARD.MENU.CUSTOMER' | translate }}</a>
              <a class="stdTheme" mat-list-item (click)="choose('contacts')">{{ 'WORK_CARD.MENU.CONTACTS' | translate }}</a>
              <a class="stdTheme" mat-list-item (click)="choose('location')">{{ 'WORK_CARD.MENU.LOCATION' | translate }}</a>
              <a class="stdTheme" mat-list-item (click)="choose('photos')">{{ 'WORK_CARD.MENU.PHOTOS' | translate }}</a>
              <a class="stdTheme" mat-list-item (click)="choose('mainEquip')">{{ 'WORK_EQUIPMENT.' + workCur.wksMainEquipment.equipType | translate }}</a>
              <div *ngIf="subEquipments !== undefined && subEquipments.length > 0">
                <div *ngFor="let item of subEquipments">
                    <a class="stdTheme" mat-list-item (click)="choose('subEquip', item)">{{ item.equipDesign | translate }}</a>
                </div>
              </div>
            </mat-nav-list>
          </mat-sidenav>
          <mat-sidenav-content fxFlexFill class="stdTheme">
            <div [ngSwitch] = "itemChoose">
                <div *ngSwitchCase="'customer'">
                    <ng-container [ngTemplateOutlet]="customerTemplate"  [ngTemplateOutletContext]="{value: workCur.workCustomer}"></ng-container> 
                </div>
                <div *ngSwitchCase="'contacts'">
                    <ng-container [ngTemplateOutlet]="contactsTemplate"  [ngTemplateOutletContext]="{value:workCur.workCustomer.dataContacts}"></ng-container>
                </div>
                <div *ngSwitchCase="'mainEquip'">  
                    <ng-container [ngTemplateOutlet]="mainEquipTemplate"  ></ng-container>
                </div>
                <div *ngSwitchCase="'subEquip'">  
                    <ng-container [ngTemplateOutlet]="subEquipTemplate"  ></ng-container>
                </div>
                <div *ngSwitchCase="'intervention'">  
                    <ng-container [ngTemplateOutlet]="interventionTemplate"  [ngTemplateOutletContext]="{value:'Say Hello'}"></ng-container>
                </div>
                <div *ngSwitchCase="'location'">  
                    <ng-container [ngTemplateOutlet]="locationTemplate"  [ngTemplateOutletContext]="{value:'Say Hello'}"></ng-container>
                </div>
                <div *ngSwitchCase="'photos'">  
                    <ng-container [ngTemplateOutlet]="photosTemplate"  [ngTemplateOutletContext]="{value:'Say Hello'}"></ng-container>
                </div>
                <div *ngSwitchDefault>
                    <ng-container [ngTemplateOutlet]="locationTemplate"  [ngTemplateOutletContext]="{value:'Say Hello'}"></ng-container>
                </div>
            </div>
          </mat-sidenav-content>
        </mat-sidenav-container>
    </div>
</mat-dialog-content>
<mat-dialog-actions *ngIf="workIsLoaded"  align="center" >
    <button class="mat-raised-button buttonTheme" mat-dialog-close style="margin-bottom: 10px;" (click)="closeModal()" >{{'COMMUN.BUTTON.CLOSE' | translate}}</button>
</mat-dialog-actions>
<ng-template let-value="value" #customerTemplate>
    <app-work-customer *ngIf="workIsLoaded"
    [workCustomer]="value"></app-work-customer>
</ng-template>
<ng-template let-value="value" #contactsTemplate>
    <app-work-contacts *ngIf="workIsLoaded"
        [ihmOptions]="ihmOptions" 
        [config]="configAddress" 
        [optionItem]="'wks-customers'" 
        [nameParent]="nameParent"
        [dataIn]="dataContacts" 
        [shareData]="shareData" 
        (dataOut)="dataOutContacts($event)"
    ></app-work-contacts>
</ng-template>
<ng-template let-value="value" #equipmentsTemplate>
    <app-work-equipments *ngIf="workIsLoaded" ></app-work-equipments>
</ng-template>
<ng-template let-value="value" #interventionTemplate>
    <app-work-intervention *ngIf="workIsLoaded"
        [ihmOptions]="ihmOptions"
        [planningOptions]="planningOptions"
        [statutCall]="'modal'" 
        [wksWorks]="workCur"
        [actionType]="'display'"
        (dataOut)="dataOutWorkIntervention($event)">
    </app-work-intervention>
</ng-template>
<ng-template let-value="value" #locationTemplate>
    <app-work-location *ngIf="workIsLoaded"
        [ihmOptions]="ihmOptions"
        [planningOptions]="planningOptions"
        [statutCall]="'modal'" 
        [wksWorks]="workCur"
        [actionType]="'display'"
        >
    </app-work-location>
</ng-template>
<ng-template  #mainEquipTemplate>
    <app-work-equipments *ngIf="workIsLoaded"
        [ihmOptions]="ihmOptions"
        [mainEquipment]="undefined"
        [equipmentCur]="workCur.wksMainEquipment"
        [statutCall]="'modal'" 
        [dateTimeFormat]="dateTimeFormat"
        [dateFormatMoment]="dateFormatMoment"
        >
    </app-work-equipments>
</ng-template>
<ng-template  #subEquipTemplate>
    <app-work-equipments *ngIf="workIsLoaded"
        [ihmOptions]="ihmOptions"
        [mainEquipment]="workCur.wksMainEquipment"
        [equipmentCur]="itemTemplate"
        [statutCall]="'modal'" 
        [dateTimeFormat]="dateTimeFormat"
        [dateFormatMoment]="dateFormatMoment"
        >
    </app-work-equipments>
</ng-template>
<ng-template  #photosTemplate>
    <app-work-photos *ngIf="workIsLoaded"
      [wksWorks] = "workCur"
      [encodedReference]= "undefined"
      [wksEntity] = "undefined"
      [callOrigin] = "undefined"
      >
    </app-work-photos>
</ng-template>