import { Component, OnInit, ViewEncapsulation, Output, EventEmitter, Input } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil, throwIfEmpty } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Security, UserAccount, UserDataJson } from 'src/app/models/oAuth.model';
import { CustomValidators, ConfirmValidParentMatcher } from '../../../../common/validators/customValidators';
import { AuthService } from '../../../../../services/auth.service';
import { ModalCommonComponent } from '../../../../../components/common/modal-common/modal-common.component';
import { AESEncryptDecryptService } from '../../../../../services/aesencrypt-decrypt.service';
import { CommonMethods } from '../../../../common/tools/commonMethods';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-update-pwd',
  templateUrl: './update-pwd.component.html',
  styleUrls: ['./update-pwd.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class UpdatePwdComponent implements OnInit {
  @Input() optionItem: string;
  private readonly onDestroy = new Subject<void>();
  @Output() returnData = new EventEmitter<string>();

  passwordCtrl: FormControl;
  confirmPasswordCtrl: FormControl;
  passwordForm: FormGroup;
  securitiesForm: FormGroup;
  identifierForm: FormGroup;
  showPassword = true;
  credentialsOK: boolean;
  securitiesOK: boolean;
  identifierOK: boolean;
  formsBuildOK: boolean;
  confirmValidParentMatcher = new ConfirmValidParentMatcher();
  configModal = {
    class: 'modal-sm modal-dialog-centered',
    backdrop: true,
    ignoreBackdropClick: true,
    animated: true,
    size: 'sm'
  };
  // securitiesDecrypt: Security[];
  userDataJson: UserDataJson;
  userAccount: UserAccount;
  securitiesDecryptNb: number;

  constructor(private fb: FormBuilder,
              private translate: TranslateService,
              private currentRoute: ActivatedRoute,
              private authService: AuthService,
              private aesEncryptDecryptService: AESEncryptDecryptService,
              private dialog: MatDialog) {
                /*
                try  {
                  if (this.currentRoute.snapshot.paramMap) {
                    this.optionItem = this.currentRoute.snapshot.params['itemOption'];
                  }
                } catch (err) {
                }
                */
               }

  ngOnInit(): void {

    this.initBooleans();
    this.buildIdentifierForm();
    this.buildPasswordForm();

    this.formsBuildOK = true;
  }
  initBooleans(): void {
    this.securitiesOK = false;
    this.identifierOK = false;
    this.credentialsOK = false;
    this.formsBuildOK = false;
  }
  buildIdentifierForm(): void {
    this.identifierForm = this.fb.group({
      identifierName: ['', Validators.required]
    });
  }
  buildPasswordForm(): void {
    this.passwordCtrl = this.fb.control('', Validators.compose([
      Validators.required,
      Validators.minLength(7),
      // this is for the letters (both uppercase and lowercase) and numbers validation
      // Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9_?!-]+$')
      // this is for the letters (both uppercase and lowercase) and numbers and special character validation
      Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*?&_-])[a-zA-Z0-9_@$!%*?&_-]+$')
    ]));
    this.confirmPasswordCtrl = this.fb.control('', Validators.required);

    this.passwordForm = this.fb.group({
        password: this.passwordCtrl,
        confirmPassword: this.confirmPasswordCtrl,
      },
      {
        validators: [CustomValidators.match('password', 'confirmPassword')],
        updateOn: 'change'
      }
    );
  }
  buildSecuritiesForm(): void {
    this.securitiesForm = this.fb.group({
      securities: this.fb.array([])
    });
    let idxQuestion = 0;
    for (const item of this.userDataJson.securitiesDecrypt) {
    // for (let i = 0; i < this.userDataJson.securitiesDecrypt.length; i++) {
      if (item.question !== '') {
        const securityForm = this.fb.group({
          question: ['', Validators.required],
          answer: ['', Validators.required]
        });
        this.securities.push(securityForm);
        idxQuestion++;
      }
    }
    this.securitiesDecryptNb = idxQuestion;
  }
  get securities(): FormArray {
    return this.securitiesForm.get('securities') as FormArray;
  }

  sendPasswd(): void {
    if (!this.passwordForm.invalid) {
      this.credentialsOK = true;
    }
  }
  ctrlPasswd(): void {
    const passwd = this.passwordForm.controls.password.value;
    const confirmPassword = this.passwordForm.controls.confirmPassword.value;
    if (confirmPassword === passwd && this.passwordForm.controls.identifierName.status === 'VALID') {
        this.sendPasswd();
    }
  }
  ctrlAnswer(idx: number): void {
    this.securitiesOK = false;
    const answerInput = CommonMethods.cleanString(this.securities.at(idx).get('answer').value).toLowerCase();
    const answerExpected = CommonMethods.cleanString(this.userDataJson.securitiesDecrypt[idx].answer).toLowerCase();
    if (answerInput === answerExpected) {
      this.securities.at(idx).get('answer').setErrors(null);
      if (this.userDataJson.securitiesDecrypt[idx + 1].question !== '') {
      // if (this.userDataJson.securitiesDecrypt.length - 1 > idx) {
        this.securities.at(idx + 1).get('question').setValue(this.userDataJson.securitiesDecrypt[idx + 1].question);
      } else {
        this.securitiesOK = true;
      }
    } else  {
      this.securities.at(idx).get('answer').setErrors({nomatch: true});
    }
  }
  identifierNameChange($event: any): void {
    // if (this.credentialsForm.controls.identifierName.valid) {
      this.identifierOK = false;
      this.verifAccount($event)
      .then ((data: any) => {
          this.userAccount = data.body as UserAccount;
          this.identifierOK = true;
          if (this.userAccount.userdata !== undefined) {
            this.userDataJson = JSON.parse(this.userAccount.userdata);
            if (this.userDataJson.securities !== undefined) {

              const token = this.userAccount.entity + this.userAccount.username + environment.constant;
              let decryptString = this.aesEncryptDecryptService.decryptUsingAES256(token, this.userDataJson.securities);
              // suppress surround ""
              decryptString = decryptString.substring(1, decryptString.length - 1);
              // decryptString = decryptString.replace(/\"\"/g,'\'');
              decryptString = decryptString.replace(/\\"/g, '"');
              this.userDataJson.securitiesDecrypt = JSON.parse(decryptString) as Security [];

              this.securitiesOK = false;
              this.buildSecuritiesForm();
              this.securities.at(0).get('question').setValue(this.userDataJson.securitiesDecrypt[0].question);
            }
          }

        // console.log(data);
      }) // loadentity
      .catch((error: any) => {
        const titleBox = this.translate.instant('ADDRESS_BLOCK.IDENTIFIER_TITLE_BOX');
        const messageBox = this.translate.instant('ADDRESS_BLOCK.IDENTIFIER_MESSAGE_BOX_2', {identifierCur: $event });
        this.displayMessageBox(titleBox, messageBox, 'WARNING', 'alertWks', 'controlIdentifier', undefined, undefined);
        this.userDataJson.securitiesDecrypt = undefined;
        this.securitiesOK = false;
      });
    // }
  }
  verifAccount(accountName: string): any {
    return new Promise((resolve, reject) => {
      this.authService.verifAccount(accountName)
      .subscribe(
        data => {
          resolve(data);
        },
        err => {
          // console.log('verifAccount' + err.message);
          if (err.status === 404) {
          }
          reject(err.status);
        }
      );
    });
  }
  getErrorMessage(ctrlName: string, idx: number): string {
    // https://www.c-sharpcorner.com/article/angular-5-reactive-forms-and-validations/
    let messageLib = '';
    switch (ctrlName) {
      case 'password': {
        !this.passwordForm.controls[ctrlName].dirty ? messageLib = '' :
          this.passwordForm.controls[ctrlName].hasError('required') ?
            messageLib = this.translate.instant('CREDENTIALS_BLOCK.PASSWORD_REQUIRED') :
            this.passwordForm.controls[ctrlName].hasError('minlength') ?
              messageLib = this.translate.instant('CREDENTIALS_BLOCK.PASSWORD_MINLENGTH') :
              this.passwordForm.controls[ctrlName].hasError('pattern') ?
                messageLib = this.translate.instant('CREDENTIALS_BLOCK.PASSWORD_PATTERN') : messageLib = '';
        break;
      }
      case 'confirmPassword': {

        !this.passwordForm.controls[ctrlName].dirty ? messageLib = '' :
          this.passwordForm.controls[ctrlName].hasError('required') ?
            messageLib = this.translate.instant('CREDENTIALS_BLOCK.PASSWORD_CONFIRMATION_REQUIRED') :
            this.passwordForm.hasError('childrenNotEqual') ?
              messageLib = this.translate.instant('CREDENTIALS_BLOCK.PASSWORD_NOT_MATCH') : messageLib = '';
        break;
      }
      case 'answer': {
        // this.securities.at(idx + 1).get('answer').setErrors({'nomatch': true});
        !this.securities.at(idx).get('answer').dirty ? messageLib = '' :
        this.securities.at(idx).get('answer').hasError('required') ?
            messageLib = this.translate.instant('CREDENTIALS_BLOCK.ANSWER_REQUIRED') :
            this.securities.at(idx).get('answer').hasError('nomatch') ?
              messageLib = this.translate.instant('CREDENTIALS_BLOCK.ANSWER_WRONG') : messageLib = '';
        break;
      }
      default: {
        break;
      }
    }
    return messageLib;
  }
  displayMessageBox(titleBoxArg: any, messageBoxArg: any, messageTypeArg: string, typeDialogArg: string,
                    actionCurArg: string, data1Arg: string, data2Arg: string): void {

  const dialogConfig = new MatDialogConfig();

  dialogConfig.disableClose = true;
  dialogConfig.autoFocus = true;
  dialogConfig.data = {
    id: 1,
    title: titleBoxArg,
    typeDialog: typeDialogArg,
    panelClass: 'stdTheme',
    contentMessage: messageBoxArg,
    data1: data1Arg,
    data2: data2Arg,
    messageType: messageTypeArg,
    actionCur: actionCurArg
  };

  const dialogRef = this.dialog.open(ModalCommonComponent, dialogConfig);

  dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        if (actionCurArg === 'pwdUpdated' ) {
          this.returnData.emit('pwdUpdated');
        }

    });

  }
  returnHome(): void {
    this.returnData.emit('exitUpdatePwd');
  }

  savePasswd(): void {

    const userAccount = this.fillModelUser();

    this.authService.saveUser(userAccount, 'updatePwd')
    .subscribe(
      () => {
        const titleBox = this.translate.instant('CREDENTIALS_BLOCK.PASSWORD_BOX_TITLE');
        const messageBox = this.translate.instant('CREDENTIALS_BLOCK.PASSWORD_UPDATED', { username: userAccount.username });
        this.displayMessageBox(titleBox, messageBox, 'INFO', 'infoWks', 'pwdUpdated', undefined, undefined);
      },
      () => {
        const titleBox = this.translate.instant('CREDENTIALS_BLOCK.PASSWORD_BOX_TITLE');
        const messageBox = this.translate.instant('CREDENTIALS_BLOCK.PASSWORD_NOT_UPDATED', {username: userAccount.username });
        this.displayMessageBox(titleBox, messageBox, 'WARNING', 'alertWks', 'pwdUpdated', undefined, undefined);
      }
    );
  }
  fillModelUser(): UserAccount {

    const userAccount: UserAccount = {
      username: this.userAccount.username,
      firstname: this.userAccount.firstname,
      lastname: this.userAccount.lastname,
      sexe: this.userAccount.sexe,
      email: this.userAccount.email,
      birthday: this.userAccount.birthday,
      // birthday: undefined,
      roles: this.userAccount.roles,
      enabled: true,
      accountNonExpired: true,
      credentialsNonExpired: true,
      userdebug: false,
      entity: this.userAccount.entity,
      usergroup: this.userAccount.usergroup,
      userlang: this.userAccount.userlang,
      password: this.passwordForm.controls.password.value,
      accountNonLocked: true,
      userdata: this.userAccount.userdata
    };
    return userAccount;

  }
}
