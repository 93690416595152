<mat-card style="padding: 10px;width: 100%;text-align: center;"  >
	<mat-card-action >
		<div class="flex-row row  stdTheme"  style="width: 100%;margin-left: 0px; margin-right: 0px">
			<form [formGroup]="snapShotForm" style="width: 100%"> 
				<div class="flex-row row  stdTheme"  style="width: 100%;margin-left: 0px; margin-right: 0px">
					<div class="col-12" style="height: 60px!important">
						<mat-form-field class="mdiinput form-group" appearance="standard" style="width: 100%!important;">
							<mat-label>{{ 'SNAPSHOT.inputDescription' | translate }}</mat-label>
							<textarea matInput
								cdkTextareaAutosize
								id="eventDescription"
								class="form-control inputTheme"
								formControlName="inputDescription" 
								#inputDescription="cdkTextareaAutosize"
								placeholder="{{ 'SNAPSHOT.inputDescription' | translate }}"
								cdkAutosizeMinRows="1"
								cdkAutosizeMaxRows="3">
							</textarea>
						</mat-form-field>
					</div>
				</div>
			</form>
		</div>
	</mat-card-action>
	<mat-card-content class="webcam-wrapper">
		<!--<div class="webcam-wrapper" *ngIf="!error">-->
			<div class="flex-row row  stdTheme d-flex justify-content-center"  style="width: 100%;margin-left: 0px; margin-right: 0px">
				<!--
				<video [class.show]="!isCaptured" [class.hide]="isCaptured" #video id="video" [width]="WIDTH" [height]="HEIGHT"	autoplay></video>
				<canvas [class.show]="isCaptured" [class.hide]="!isCaptured" #canvas id="canvas" [width]="WIDTH" [height]="HEIGHT"></canvas>
				-->
				<video #video [width]="getVideoWidth()" [height]="getVideoHeight()" [class.show]="!isCaptured" [class.hide]="isCaptured"   autoplay muted playsinline (resize)="videoResize()"></video>
				<!--<div class="camera-switch" *ngIf="allowCameraSwitch && availableVideoInputs.length > 1 && videoInitialized" (click)="rotateVideoInput(true)"></div>-->
				<canvas [class.show]="isCaptured" [class.hide]="!isCaptured"  #canvas id="canvas" [width]="getVideoWidth()" [height]="getVideoHeight()"></canvas>
			</div>
		<!--</div>-->
	</mat-card-content>
	<mat-card-footer class="snap-container" >
		<!--<div class="snap-container">-->
			<div class="flex-row row  stdTheme d-flex justify-content-between"  style="width: 100%;margin-left: 0px; margin-right: 0px">
					<!--<p [innerHTML]="eventText"></p>-->
					<button  mat-raised-button (click)="rotateVideoInput(true);" [disabled]="!multipleWebcamsAvailable || isCaptured">
						<fa-icon [icon]="faCameraRotate" style="font-size: 20px"></fa-icon>
					</button>
					<button class="btn btn-primary" *ngIf="!isCaptured" (click)="capture()">
						<fa-icon [icon]="faImage" style="font-size: 20px"></fa-icon>
					</button>
					<button class="btn btn-danger" *ngIf="isCaptured" (click)="resetCurrent()">
						<fa-icon [icon]="faPlus" style="font-size: 20px"></fa-icon>
					</button>
					<button  mat-raised-button (click)="uploadPhotos();" [disabled]="captures.length < 1">
						<fa-icon [icon]="faUpload" style="font-size: 20px"></fa-icon>
					</button>
			</div>
			
			<div class="flex-row row  stdTheme"  style="width: 100%;margin-left: 0px; margin-right: 0px">
				<div class="col-12">
					<ul class="list-unstyled">
						<li *ngFor="let src of captures; let idx = index" (click)="setPhoto(idx)">
							<img (swipe)="onSwipe($event, idx)" [src]="src" height="50" />
						</li>
					</ul>
				</div>
			</div>
		<!--</div>-->
	</mat-card-footer>
</mat-card>