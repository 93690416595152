export const environment = {
  envName: 'prod',
  applicationName: 'NautikoNws Appointments prod',
  production: true,
  version: '1.0.147',
  buildTimestamp: '2024-11-21 16:14:42',
  // baseUrl: 'https://192.168.1.59:8443',
  baseUrl: 'https://appointments.nautikonws.org/backend',
  keycloakProp: {
    // url: 'https://auth.nautikonws.org/auth/realms',
    url: 'https://appointments.nautikonws.org/auth/realms',
    realm: 'nautikoWS-realm',
    clientId: 'nautikoWS-api',
    clientSecret: '27028874-0886-4fc3-88b2-b68c1043589d',
    entityAuth: 'Az789-!!HSJ654wosr*$kk__KKK',
  },
  // https://stackblitz.com/edit/angular-pvwfjq?file=app/input-overview-example.html
  directionInput : [
    { lang: 'en', direction: 'lrt' },
    { lang: 'fr', direction: 'lrt' },
    { lang: 'ar', direction: 'rtl' }
  ],

  indusArabicsNumbers: {
    comment: 'Hindus-arabics : https://omniglot.com/language/numbers/arabic.htm ',
    units: [
      {latin: '0', hindus: '٠'},
      {latin: '1', hindus: '١'},
      {latin: '2', hindus: '٢'},
      {latin: '3', hindus: '٣'},
      {latin: '4', hindus: '٤'},
      {latin: '5', hindus: '٥'},
      {latin: '6', hindus: '٦'},
      {latin: '7', hindus: '٧'},
      {latin: '8', hindus: '٨'},
      {latin: '9', hindus: '٩'}
    ]
  },
  fmtDateTimeBdd: 'YYYY-MM-DD HH:mm:ss',
  fmtDateBdd: 'YYYY-MM-DD',
  fmtHourBdd: 'HH:mm',
  fmtDateTimeTZ: 'YYYY-MM-DDTHH:mm:ss.SSS+0000',
  emailAttr : {
    emailTypeList: ['prof', 'perso'],
    emailRegex: '(^[a-z]+[a-z0-9_\.]+)(@)([a-z]+)([\.][a-z]{2,5}$)',
    emailDisplay: '$1$2$3$4',
    emailLengthMax: '60',
  },
  constant: '1951'
};
