<div *ngIf="actionCur !== 'TimeChanged' 
            && actionCur !== 'submitCreate' 
            && actionCur !== 'pwdUpdated'
            && actionCur !== 'htmlMessage'">
    <div *ngFor="let alert of alerts" class="{{ cssClass(alert) }} alert-dismissable" 
                        style="padding-top: 0px;padding-bottom: 0px;margin-bottom: 0px;">
        {{ alert.message | translate:[alert.data1, alert.data2] }}
        <a *ngIf="alert.alertType !== 'alertWks'" class="close" (click)="removeAlert(alert)">&times;</a>
    </div>
</div>
<div *ngIf="actionCur === 'TimeChanged'">
    <div *ngFor="let alert of alerts" class="{{ cssClass(alert) }} alert-dismissable" 
        style="padding-top: 0px;padding-bottom: 0px;margin-bottom: 0px;">
        {{ alert.message }} <br>
        {{ alert.data1 }} <br>
        {{ alert.data2 }} <br>
    </div>
</div>
<div *ngIf="(actionCur === 'submitCreate' || actionCur === 'pwdUpdated' || actionCur === 'htmlMessage') && isOkToDisplay">
    <div *ngFor="let alert of alerts" class="{{ cssClass(alert) }} alert-dismissable" 
        style="padding-top: 0px;padding-bottom: 0px;margin-bottom: 0px;text-align:center"
            [innerHTML]="alert.message | safeHtml: 'html'">
    </div>
</div>